import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './GoOrcamento.module.css'

class GoOrcamento extends Component {
  render () {
    const { lang } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <img className={styles.orcamentoIcon} src='images/orcamento-icon.svg' alt='' />
          <img className={styles.pointerIcon} src='images/Pointer.png' alt='' />
          {
            lang === 'pt'
              ? this.pt()
              : this.en()
          }
        </div>
      </div>
    )
  }

  pt () {
    return (
      <div className={styles.textBox}>
        <div className={styles.h1}>Deseja um orçamento?</div>
        <div className={styles.line} />
        <div className={styles.textColor + ' ' + styles.column}>
          <div>Vá à lista de artigos</div>
          <div>Filtre por família e características</div>
          <div>Adicione artigos ao orçamento</div>
          <div>Em Orçamento complete o formulário e envie-nos</div>
          <span className={styles.textSpan}>É rápido e intuitivo. A nossa resposta será ainda mais do seu agrado...</span>
        </div>
      </div>
    )
  }

  en () {
    return (
      <div className={styles.textBox}>
        <div className={styles.h1}>Wish a product quote?</div>
        <div className={styles.line} />
        <div className={styles.textColor + ' ' + styles.column}>
          <div>Go to the product list</div>
          <div>Search or filter by family</div>
          <div>Add products to the list</div>
          <div>On Quote fill the forma and send us</div>
          <span className={styles.textSpan}>It's quick and intuitive. You'll be pleased with our answer...</span>
        </div>
      </div>
    )
  }
}

GoOrcamento.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GoOrcamento)
