import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import styles from './Slide.module.css'

export default class Slide extends Component {
  render () {
    const { text, type, to } = this.props
    if (!to) {
      return (
        <div className={styles.container}>
          <div className={styles.imageCont + ' ' + styles.img + ' ' + styles[type]} />
          <div className={styles.text}>{text}</div>
        </div>
      )
    }
    return (
      <div className={styles.container}>
        <Link to={to} className={styles.imageCont + ' ' + styles.img + ' ' + styles[type]} />
        <Link to={to} className={styles.text}>{text}</Link>
      </div>
    )
  }
}
