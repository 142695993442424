import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { createResponsiveStateReducer } from 'redux-responsive'

import language from './modules/language'
import artigos from './modules/artigos'
import carroConsulta from './modules/carroConsulta'
import menuFamilias from './modules/menuFamilias'
import familias from './modules/familias'
import contactos from './modules/contactos'
import geral from './modules/geral'
import search from './modules/search'

export default combineReducers({
  routing: routerReducer,
  keycloak: (keycloak = {}) => keycloak,
  browser: createResponsiveStateReducer({
    extraSmall: 500,
    small: 767,
    medium: 960,
    large: 1280,
    extraLarge: 1400
  }),
  language,
  artigos,
  carroConsulta,
  menuFamilias,
  familias,
  contactos,
  geral,
  search
})