import axios from 'axios'
import { host } from '../request'
const SET_FAMILIA = 'bil/familias/SET_FAMILIA'
const FAMILIA_LOADING = 'bil/familias/FAMILIA_LOADING'
const FAMILIA_LOADING_ERROR = 'bil/familias/FAMILIA_LOADING_ERROR'
const SET_FAMILIAS = 'bil/familias/SET_FAMILIAS'
const FAMILIAS_LOADING = 'bil/familias/FAMILIAS_LOADING'
const FAMILIAS_LOADING_ERROR = 'bil/familias/FAMILIAS_LOADING_ERROR'

const initialState = {
  currentFamilia: null,
  familiaIsLoading: false,
  familiaLoadingErrored: false,
  familias: [],
  familiasAreLoading: false,
  familiasLoadingErrored: false
}

export const setFamilia = (familia) => ({ type: SET_FAMILIA, familia })
export const familiaLoading = (loading) => ({ type: FAMILIA_LOADING, loading })
export const familiaLoadingError = (errored) => ({ type: FAMILIA_LOADING_ERROR, errored })

export const familiasLoading = (loading) => ({ type: FAMILIAS_LOADING, loading })
export const familiasLoadingError = (errored) => ({ type: FAMILIAS_LOADING_ERROR, errored })

export function fetchFamilia (lang, id) {
  return (dispatch) => {
    dispatch(familiaLoading(true))
    dispatch(familiaLoadingError(false))
    axios.get(`${host}/api/public/familia/${id}?lang=${lang}`)
      .then(res => res.data)
      .then(post => {
        dispatch({
          type: SET_FAMILIA,
          familia: post
        })
        dispatch(familiaLoading(false))
      })
      .catch(error => {
        console.log(`error`, error)
        dispatch(familiaLoading(false))
        dispatch(familiaLoadingError(true))
      })
  }
}

export function fetchFamilias (lang) {
  return (dispatch) => {
    dispatch(familiasLoading(true))
    dispatch(familiasLoadingError(false))
    axios.get(`${host}/api/public/familiasComArtigos?lang=${lang}`)
      .then(res => res.data)
      .then(posts => {
        posts = posts.sort((a, b) => {
          let nomeA = a.nome.toUpperCase()
          let nomeB = b.nome.toUpperCase()
          if (nomeA < nomeB) {
            return -1
          }
          if (nomeA > nomeB) {
            return 1
          }
          return 0
        })
        dispatch({
          type: SET_FAMILIAS,
          familias: posts
        })
        dispatch(familiasLoading(false))
      })
      .catch(error => {
        console.log(`error`, error)
        dispatch(familiasLoading(false))
        dispatch(familiasLoadingError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_FAMILIA:
      return { ...state, currentFamilia: action.familia }
    case FAMILIA_LOADING:
      return { ...state, familiaIsLoading: action.loading }
    case FAMILIA_LOADING_ERROR:
      return { ...state, familiaLoadingErrored: action.errored }
    case SET_FAMILIAS:
      return { ...state, familias: action.familias }
    case FAMILIAS_LOADING:
      return { ...state, familiasAreLoading: action.loading }
    case FAMILIAS_LOADING_ERROR:
      return { ...state, familiasLoadingError: action.errored }
    default:
      return state
  }
}