const TOGGLE_LANGUAGE = 'bil/language/TOGGLE_LANGUAGE'
const SET_LANG = 'bil/language/SET_LANG'

const initialState = {
  expanded: false,
  lang: 'pt',
  langs: ['pt', 'en']
}

export const toggleLanguage = () => ({ type: TOGGLE_LANGUAGE })
export const setLang = (lang) => ({ type: SET_LANG, lang })

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return { ...state, expanded: !state.expanded }
    case SET_LANG:
      return { ...state, lang: action.lang }
    default:
      return state
  }
}