import React, { Component } from 'react'

import styles from './MenuHeader.module.css'

export default class MenuHeader extends Component {
  render () {
    const { title, subtitle } = this.props
    return (
      <div className={styles.main}>
        <h1 className={styles.h1}>{title}</h1>
        {
          subtitle
            ? <div className={styles.subtitle}>{subtitle}</div>
            : null
        }
      </div >
    )
  }
}
