import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './LangMenu.module.css'
import { toggleLanguage, setLang } from '../../modules/language'

class LangMenu extends Component {
  render () {
    const { lang, langs, expanded } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.menu + (expanded ? ' ' + styles.expanded : '')} onClick={() => this.toogleMenu()}>{lang}
          {this.buildMenu(lang, langs, expanded)}
        </div>
      </div>
    )
  }

  toogleMenu () {
    this.props.toggleLanguage()
  }

  buildMenu (lang, langs, expanded) {
    if (!expanded) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          langs.filter((item) => (lang !== item)).map((item, index) => (
            <li key={index} id={index} className={styles.li} onClick={() => this.selectItem(item)}>{item}</li>)
          )
        }
      </ul>
    )
  }

  selectItem (lang) {
    this.props.setLang(lang)
  }
}

LangMenu.propTypes = {
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
  setLang: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang,
  langs: state.language.langs,
  expanded: state.language.expanded
})

const mapDispatchToProps = { toggleLanguage, setLang }

export default connect(mapStateToProps, mapDispatchToProps)(LangMenu)
