import React, { Component } from 'react'

import styles from './BottomFooter.module.css'
import logoimg from '../../images/compete2020-1-1.png'

export default class BottomFooter extends Component {
  render () {
    return (
      <div className={styles.main}>
        <img src={logoimg}
          alt='LogoSponser'
          width='400'
          className={styles.logos} />
      </div>
    )
  }
}
