import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './ListNav.module.css'
import { goPage } from '../../modules/search'

class ListNav extends Component {
  render () {
    const maxPages = 15
    const { currentPage, totalPages } = this.props
    if (!totalPages || totalPages <= 1) {
      return null
    }
    return (
      <div className={styles.main}>
        {this.drawMovePrev(currentPage)}
        {this.drawPages(currentPage, totalPages, maxPages)}
        {this.drawMoveNext(currentPage, totalPages)}
      </div>
    )
  }

  drawMovePrev (currentPage) {
    const canMove = currentPage > 1 ? true : false
    if (!canMove) {
      return (
        <div className={styles.setaLeft + ' ' + styles.disabled} />
      )
    }
    return (
      <div className={styles.setaLeft}
        onClick={() => this.goPage(currentPage - 1)} />
    )
  }

  drawMoveNext (currentPage, totalPages) {
    const canMove = currentPage < totalPages ? true : false
    if (!canMove) {
      return (
        <div className={styles.setaRight + ' ' + styles.disabled} />
      )
    }
    return (
      <div className={styles.setaRight}
        onClick={() => this.goPage(currentPage + 1)} />
    )
  }

  drawPages (currentPage, totalPages, maxPages) {
    let arr = []
    let index = 0
    const startPage = totalPages <= maxPages ? 0 : currentPage - 1
    if (startPage > 0) {
      arr.push(<div key={startPage - 1} className={styles.morePages} />)
    }
    const iterations = totalPages <= maxPages ? totalPages : startPage + maxPages
    for (index = startPage; index < iterations; index++) {
      if (index + 1 === currentPage) {
        arr.push(<div key={index} className={styles.currentPage}>{index + 1}</div>)
      } else {
        const page = index + 1
        arr.push(<div key={index} className={styles.pageNumber} onClick={() => this.goPage(page)}>{index + 1}</div>)
      }
    }
    if (totalPages > startPage + maxPages) {
      arr.push(<div key={startPage + maxPages} className={styles.morePages} />)
    }
    return arr
  }

  goPage (page) {
    console.log(`page`, page)
    const { state } = this.props
    this.props.goPage(page, state)
  }
}

ListNav.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  goPage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentPage: state.search.currentPage,
  totalPages: state.search.totalPages,
  artigosFromSearch: state.search.artigosFromSearch,
  state: state.search
})

const mapDispatchToProps = { goPage }

export default connect(mapStateToProps, mapDispatchToProps)(ListNav)
