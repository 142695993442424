import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { FormattedMessage as FM } from 'react-intl'

import styles from './Project.module.css'
import PageHeader from '../PageHeader/PageHeader'
// import messages from '../../messages'
import ProjectPt from './ProjectPt'
import ProjectEn from './ProjectEn'
import { setStaticMenu } from '../../modules/geral'

class Project extends Component {
  constructor(props) {
    super(props)
    const { match: { path } } = props
    this.props.setStaticMenu(path)
  }

  render () {
    const { lang } = this.props
    return (
      <div className={styles.main}>
        <PageHeader
          title={'project.header.title'}
          subtitle={null}
          shadowTitle
          formname={'project'} />
        {
          lang === 'pt'
            ? <ProjectPt />
            : <ProjectEn />
        }
      </div>
    )
  }
}

Project.propTypes = {
  lang: PropTypes.string.isRequired,
  setStaticMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = { setStaticMenu }

export default connect(mapStateToProps, mapDispatchToProps)(Project)
