const messages = {
  pt: {
    'login.login': 'Login',
    'login.logout': 'Logout',
    'menuInst.listaConsulta': 'Orçamento',
    'menuInst.artigos': 'Artigos',
    'menuInst.about': 'Sobre nós',
    'menuInst.projeto': 'Projeto',
    'menuInst.novidades': 'Novidades',
    'menuInst.contatos': 'Contatos',
    'menuInst.entregasFast': 'Entregas FAST',
    'menuInst.qualidades': 'Qualidade',
    'contacto.header.title': 'Diga-nos olá. É grátis e rápido',
    'contacto.header.subtitle': 'Diga-nos em que lhe podemos ser útil e entraremos em contacto consigo em breve. Obrigado!',
    'contacto.title': 'Formulário de contacto',
    'contacto.form.nome': 'Nome',
    'contacto.form.telef': 'Telefone/Telemóvel',
    'contacto.form.email': 'Email',
    'contacto.form.empresa': 'Empresa',
    'contacto.form.mensagem': 'Mensagem',
    'contacto.form.nomeErr': 'Introduzir o nome',
    'contacto.form.telefErr': 'Introduzir o telefone/telemóvel',
    'contacto.form.emailErr': 'Introduzir o email',
    'contacto.form.empresaErr': 'Introduzir a Empresa',
    'contacto.form.mensagemErr': 'Introduzir a mensagem',
    'contacto.info.morada': 'Morada',
    'contacto.info.codPostal': 'Código postal',
    'contacto.info.pais': 'País',
    'contacto.info.telef': 'Telefone',
    'contacto.info.telefType': '(chamada para a rede fixa nacional)',
    'contacto.info.email': 'Email',
    'project.header.title': 'Ficha de projecto',
    'aboutUs.header.title': 'Uma parceria com 75 anos de história',
    'aboutUs.info.title': 'Materializamos os nossos parceiros',
    'artigos.title': 'Artigos disponíveis',
    'artigos.listaConsulta.title': 'Lista de consulta',
    'artigos.familiaFilter.title': 'Família',
    'artigos.artigosFilter.title': 'Filtro de artigos',
    'artigos.artigosLista.title': 'Lista de artigos',
    'carroConsulta.title': 'Pedido de Orçamento',
    'carroConsulta.artigos.title': 'Artigos Selecionados',
    'carroConsulta.contact.title': 'Dados de contacto',
    'carroConsulta.empresa': 'Nome da Empresa',
    'carroConsulta.empresaErr': 'O campo empresa é obrigatório',
    'carroConsulta.nif': 'NIF',
    'carroConsulta.nifErr': 'O NIf é obrigatório',
    'carroConsulta.nomeContacto': 'Contato',
    'carroConsulta.nomeContactoErr': 'O campo contato é obrigatório',
    'carroConsulta.telefone': 'Telefone ou telemóvel do contato',
    'carroConsulta.telefoneErr': 'O campo telefone é obrigatório',
    'carroConsulta.telefoneInvalid': 'O telefone não é válido',
    'carroConsulta.email': 'Email',
    'carroConsulta.emailErr': 'O campo email é obrigatório',
    'carroConsulta.dataPretendida': 'Data de entrega pretendida',
    'carroConsulta.urgente': 'Urgente',
    'carroConsulta.obs': 'Observações',
    'carroConsulta.requestData.title': 'Dados do pedido',
    'carroConsulta.artigos.artigo': 'Artigo',
    'carroConsulta.artigos.unidades': 'Unidades',
    'carroConsulta.itemConsulta.unidades': 'Unidades',
    'carroConsulta.itemConsulta.qualidade': 'Qualidade do artigo',
    'carroConsulta.itemConsulta.obs': 'Observações',
    'carroConsulta.itemConsulta.files': 'Anexos',
    'carroConsulta.itemConsulta.file.de': 'de',
    'carroConsulta.itemConsulta.file.disponiveis': 'disponíveis',
    'artigoCustom.title': 'Artigo personalizado',
    'artigoCustom.help': 'Não encontra o artigo? Pode descrever e adicionar diretamente',
    'artigoCustom.descricao': 'Descrição do artigo',
    'artigoCustom.descricaoErr': 'A descrição é obrigatória',
    'artigoCustom.addAction': 'Adicionar a Orçamento',
    'topFooter.email1.label': 'Qualidade:',
    'topFooter.email2.label': 'Logistica:',
    'topFooter.email3.label': 'Redes e novos produtos:',
    'topFooter.email4.label': 'Reclamações:',
    'sabiaQue.title': 'Sabia que?',
    'sabiaQue.message': 'A versatilidade dos nossos serviços logísticos permite-nos entregar diariamente mais de 65 clientes de Norte a Sul do País.',
    'familia.descricao': 'Descrição',
    'familia.infoTecnica': 'Informação técnica',
    'familia.qualidades': 'Qualidades',
    'entregas.header.title': 'Serviço de entregas',
    'artigos.search.label': 'Pesquisa de artigos',
    'artigos.search.placeholder': 'Por ex: Tubos 140x10',
    'action.send': 'Enviar',
    'artigos.filterbyfammily': 'Filtre por família OU use a caixa de pesquisa para pesquisar artigos',
    'artigos.addToOrcamento': 'Clique em "Adicionar" para adicionar ao',
    'artigos.addToOrcamentoLink': 'Pedido de Orçamento',
    'toast.success': 'Sucesso',
    'toast.error': 'Erro',
    'toast.help': 'Ajuda',
    'toast.info': 'Info',
    'toast.consultaSubmittedSuccess': 'O pedido de orçamentação foi submetido com sucesso.',
    'toast.consultaErrored': 'Ocorreu um erro ao submeter o pedido de orçamentação',
    'toast.contactosVisited': 'Os campos assinalados com * são de preenchimento obrigatório',
    'toast.contactoSubmissionSucceeded': 'A sua mensagem foi submetida',
    'toast.contactoSubmissionErrored': 'Ocorreu um erro no envio da mensagem',
    'toast.carroConsultaVisited': 'Para submeter um pedido deverá no mínimo ter um elemento na lista',
    'toast.customArtigoAdded': 'Artigo personalizado adicionado à lista de orçamento',
    'toast.artigoAdded': 'Artigo adicionado à lista de orçamento',
    'gamaProdutos.title': 'Gama de Produtos',
    'gamaProdutos.subtitle': 'CONHEÇA O MUNDO BIL POR DENTRO',
    'gamaProdutos.text1': 'Verifique',
    'gamaProdutos.linkText': 'AQUI',
    'gamaProdutos.text2': 'a gama comercial e Fabricada da nossa empresa',
    'qualidades.title': 'Qualidade - BIL',
    'artigo.addShort': 'Adicionar',
    'artigo.removeShort': 'Remover'
  },
  en: {
    'login.login': 'Login',
    'login.logout': 'Logout',
    'menuInst.listaConsulta': 'Quote',
    'menuInst.artigos': 'Products',
    'menuInst.about': 'About us',
    'menuInst.projeto': 'Project',
    'menuInst.novidades': 'News',
    'menuInst.contatos': 'Contacts',
    'menuInst.entregasFast': 'FAST Deliveries',
    'menuInst.qualidades': 'Quality',
    'contacto.header.title': 'Say hello to us. It\'s quick and free ',
    'contacto.header.subtitle': 'Tell us hwo can we help you and we will reach you as soon as possible. Thank you!',
    'contacto.title': 'Contact form',
    'contacto.form.nome': 'Name',
    'contacto.form.telef': 'Phone/mobile',
    'contacto.form.email': 'Email',
    'contacto.form.empresa': 'Company',
    'contacto.form.mensagem': 'Message',
    'contacto.form.nomeErr': 'Name required',
    'contacto.form.telefErr': 'Phone/mobile required',
    'contacto.form.emailErr': 'Email required',
    'contacto.form.empresaErr': 'Company required',
    'contacto.form.mensagemErr': 'Message required',
    'contacto.info.morada': 'Address',
    'contacto.info.codPostal': 'Zip code',
    'contacto.info.pais': 'Country',
    'contacto.info.telef': 'Phone',
    'contacto.info.telefType': '(Portuguese land line call)',
    'contacto.info.email': 'Email',
    'project.header.title': 'Project file',
    'aboutUs.header.title': 'A partnership with 75 years of history',
    'aboutUs.info.title': 'Materializing our partners',
    'artigos.title': 'Products available',
    'artigos.listaConsulta.title': 'Query list',
    'artigos.familiaFilter.title': 'Family',
    'artigos.artigosFilter.title': 'Product filter',
    'artigos.artigosLista.title': 'Product list',
    'carroConsulta.title': 'Product price request',
    'carroConsulta.artigos.title': 'Selected Products',
    'carroConsulta.contact.title': 'Contact data',
    'carroConsulta.empresa': 'Company name',
    'carroConsulta.empresaErr': 'Company name is required',
    'carroConsulta.nif': 'Tax Number',
    'carroConsulta.nifErr': 'Tax number is required',
    'carroConsulta.nomeContacto': 'Contact',
    'carroConsulta.nomeContactoErr': 'Contact name is required',
    'carroConsulta.telefone': 'Phone or mobile number',
    'carroConsulta.telefoneErr': 'Phone or mobile number is required',
    'carroConsulta.telefoneInvalid': 'Invalide phone number',
    'carroConsulta.email': 'Email',
    'carroConsulta.emailErr': 'Email is required',
    'carroConsulta.dataPretendida': 'Delivery date pretended',
    'carroConsulta.urgente': 'Urgent',
    'carroConsulta.obs': 'Observations',
    'carroConsulta.requestData.title': 'Consulting request data',
    'carroConsulta.artigos.artigo': 'Product',
    'carroConsulta.artigos.unidades': 'Quantity',
    'carroConsulta.itemConsulta.unidades': 'Quantity',
    'carroConsulta.itemConsulta.qualidade': 'Product quality',
    'carroConsulta.itemConsulta.obs': 'Remarks',
    'carroConsulta.itemConsulta.files': 'Attachments',
    'carroConsulta.itemConsulta.file.de': 'of',
    'carroConsulta.itemConsulta.file.disponiveis': 'available',
    'artigoCustom.title': 'Custom product',
    'artigoCustom.help': 'Can\'t find a product? Just type a description and click to add',
    'artigoCustom.addAction': 'Add to Quote list',
    'topFooter.email1.label': 'Quality:',
    'topFooter.email2.label': 'Logistics:',
    'topFooter.email3.label': 'Nets & new Prodcts:',
    'topFooter.email4.label': 'Complaint:',
    'sabiaQue.title': 'Did you know?',
    'sabiaQue.message': 'Our logistic skills allow us to daily serve more then 65 clients from all over the Country.',
    'familia.descricao': 'Description',
    'familia.infoTecnica': 'Tecnical Information',
    'familia.qualidades': 'Qualities',
    'entregas.header.title': 'Delivery service',
    'artigos.search.label': 'Product search',
    'artigos.search.placeholder': 'For example: Tubos 140x10',
    'action.send': 'Send',
    'artigos.filterbyfammily': 'Filter by family OR use the search box to find products',
    'artigos.addToOrcamento': 'Click in the "Add" button to add to the',
    'artigos.addToOrcamentoLink': 'Quote Request',
    'toast.success': 'Success',
    'toast.error': 'Error',
    'toast.help': 'Help',
    'toast.info': 'Info',
    'toast.consultaSubmittedSuccess': 'Quote request submitted',
    'toast.consultaErrored': 'An error occured on the request submission',
    'toast.contactosVisited': 'Fields with star (*) are mandatory',
    'toast.contactoSubmissionSucceeded': 'Your message has been submitted',
    'toast.contactoSubmissionErrored': 'Error sending message',
    'toast.carroConsultaVisited': 'To submit a quote at least one product must be in the list',
    'toast.customArtigoAdded': 'Custom product added to the quotes list',
    'toast.artigoAdded': 'Product added to the quotes list',
    'gamaProdutos.title': 'Products Offer',
    'gamaProdutos.subtitle': 'KNOW THE BIL WORLD FROM THE INSIDE',
    'gamaProdutos.text1': 'Check',
    'gamaProdutos.linkText': 'HERE',
    'gamaProdutos.text2': 'the commercial and manufactured range of our company',
    'qualidades.title': 'Quality - BIL',
    'artigo.addShort': 'Add',
    'artigo.removeShort': 'Remove'
  },
  es: {
    'login.login': 'Login',
    'login.logout': 'Logout',
    'menuInst.listaConsulta': 'Orçamento',
    'menuInst.artigos': 'Artigos',
    'menuInst.about': 'Sobre nós',
    'menuInst.projeto': 'Projeto',
    'menuInst.novidades': 'Novidades',
    'menuInst.contatos': 'Contatos',
    'menuInst.entregasFast': 'Entregas FAST',
    'menuInst.qualidades': 'Qualidade',
    'contacto.header.title': 'Diga-nos olá. É grátis e rápido',
    'contacto.header.subtitle': 'Diga-nos em que lhe podemos ser útil e entraremos em contacto consigo em breve. Obrigado!',
    'contacto.title': 'Formulário de contacto',
    'contacto.form.nome': 'Nome',
    'contacto.form.telef': 'Telefone/Telemóvel',
    'contacto.form.email': 'Email',
    'contacto.form.empresa': 'Empresa',
    'contacto.form.mensagem': 'Mensagem',
    'contacto.form.nomeErr': 'Introduzir o nome',
    'contacto.form.telefErr': 'Introduzir o telefone/telemóvel',
    'contacto.form.emailErr': 'Introduzir o email',
    'contacto.form.empresaErr': 'Introduzir a Empresa',
    'contacto.form.mensagemErr': 'Introduzir a mensagem',
    'contacto.info.morada': 'Morada',
    'contacto.info.codPostal': 'Código postal',
    'contacto.info.pais': 'País',
    'contacto.info.telef': 'Telefone',
    'contacto.info.telefType': '(chamada para a rede fixa nacional)',
    'contacto.info.email': 'Email',
    'project.header.title': 'Ficha de projecto',
    'aboutUs.header.title': 'Uma parceria com 75 anos de história',
    'aboutUs.info.title': 'Materializamos os nossos parceiros',
    'artigos.title': 'Artigos disponíveis',
    'artigos.listaConsulta.title': 'Lista de consulta',
    'artigos.familiaFilter.title': 'Família',
    'artigos.artigosFilter.title': 'Filtro de artigos',
    'artigos.artigosLista.title': 'Lista de artigos',
    'carroConsulta.title': 'Pedido de Orçamento',
    'carroConsulta.artigos.title': 'Artigos Selecionados',
    'carroConsulta.contact.title': 'Dados de contacto',
    'carroConsulta.empresa': 'Nome da Empresa',
    'carroConsulta.empresaErr': 'O campo empresa é obrigatório',
    'carroConsulta.nif': 'NIF',
    'carroConsulta.nifErr': 'O NIf é obrigatório',
    'carroConsulta.nomeContacto': 'Contato',
    'carroConsulta.nomeContactoErr': 'O campo contato é obrigatório',
    'carroConsulta.telefone': 'Telefone ou telemóvel do contato',
    'carroConsulta.telefoneErr': 'O campo telefone é obrigatório',
    'carroConsulta.telefoneInvalid': 'O telefone não é válido',
    'carroConsulta.email': 'Email',
    'carroConsulta.emailErr': 'O campo email é obrigatório',
    'carroConsulta.dataPretendida': 'Data de entrega pretendida',
    'carroConsulta.urgente': 'Urgente',
    'carroConsulta.obs': 'Observações',
    'carroConsulta.requestData.title': 'Dados do pedido',
    'carroConsulta.artigos.artigo': 'Artigo',
    'carroConsulta.artigos.unidades': 'Unidades',
    'carroConsulta.itemConsulta.unidades': 'Unidades',
    'carroConsulta.itemConsulta.qualidade': 'Qualidade do artigo',
    'carroConsulta.itemConsulta.obs': 'Observações',
    'carroConsulta.itemConsulta.files': 'Anexos',
    'carroConsulta.itemConsulta.file.de': 'de',
    'carroConsulta.itemConsulta.file.disponiveis': 'disponíveis',
    'artigoCustom.title': 'Artigo personalizado',
    'artigoCustom.help': 'Não encontra o artigo? Pode descrever e adicionar diretamente',
    'artigoCustom.descricao': 'Descrição do artigo',
    'artigoCustom.descricaoErr': 'A descrição é obrigatória',
    'artigoCustom.addAction': 'Adicionar a Orçamento',
    'topFooter.email1.label': 'Qualidade:',
    'topFooter.email2.label': 'Logistica:',
    'topFooter.email3.label': 'Redes e novos produtos:',
    'topFooter.email4.label': 'Reclamações:',
    'sabiaQue.title': 'Sabia que?',
    'sabiaQue.message': 'A versatilidade dos nossos serviços logísticos permite-nos entregar diariamente mais de 65 clientes de Norte a Sul do País.',
    'familia.descricao': 'Descrição',
    'familia.infoTecnica': 'Informação técnica',
    'familia.qualidades': 'Qualidades',
    'entregas.header.title': 'Serviço de entregas',
    'artigos.search.label': 'Pesquisa de artigos',
    'artigos.search.placeholder': 'Por ex: Tubos 140x10',
    'action.send': 'Enviar',
    'artigos.filterbyfammily': 'Filtre por família OU use a caixa de pesquisa para pesquisar artigos',
    'artigos.addToOrcamento': 'Clique em "Adicionar" para adicionar ao',
    'artigos.addToOrcamentoLink': 'Pedido de Orçamento',
    'toast.success': 'Sucesso',
    'toast.error': 'Erro',
    'toast.help': 'Ajuda',
    'toast.info': 'Info',
    'toast.consultaSubmittedSuccess': 'O pedido de orçamentação foi submetido com sucesso.',
    'toast.consultaErrored': 'Ocorreu um erro ao submeter o pedido de orçamentação',
    'toast.contactosVisited': 'Os campos assinalados com * são de preenchimento obrigatório',
    'toast.contactoSubmissionSucceeded': 'A sua mensagem foi submetida',
    'toast.contactoSubmissionErrored': 'Ocorreu um erro no envio da mensagem',
    'toast.carroConsultaVisited': 'Para submeter um pedido deverá no mínimo ter um elemento na lista',
    'toast.customArtigoAdded': 'Artigo personalizado adicionado à lista de orçamento',
    'toast.artigoAdded': 'Artigo adicionado à lista de orçamento',
    'gamaProdutos.title': 'Gama de Produtos',
    'gamaProdutos.subtitle': 'CONHEÇA O MUNDO BIL POR DENTRO',
    'gamaProdutos.text1': 'Verifique',
    'gamaProdutos.linkText': 'AQUI',
    'gamaProdutos.text2': 'a gama comercial e Fabricada da nossa empresa',
    'qualidades.title': 'Qualidade - BIL',
    'artigo.addShort': 'Adicionar',
    'artigo.removeShort': 'Remover'
  }
}

export default messages