import React, { Component } from 'react'

import styles from './Footer.module.css'
import TopFooter from './TopFooter'
import BottomFooter from './BottomFooter'

export default class Footer extends Component {
  render() {
    return (
      <div className={styles.main}>
        <TopFooter />
        <BottomFooter />
      </div>
    )
  }
}
