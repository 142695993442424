import axios from 'axios'
import { host, generateUUID } from '../request'

const UPDATE_CONTACTO = 'bil/contactos/UPDATE_CONTACTO'

const CONTACTO_SUBMITTED = 'bil/contactos/CONTACTO_SUBMITTED'
const SUBMIT_CONTACTO_ERROR = 'bil/contactos/SUBMIT_CONTACTO_ERROR'
const SUBMITTING_CONTACTO = 'bil/contactos/SUBMITTING_CONTACTO'
const SUBMIT_CONTACTO_SUCCESS = 'bil/contactos/SUBMIT_CONTACTO_SUCCESS'
const RESET_CONTACTO = 'bil/contactos/RESET_CONTACTO'


export const updateContacto = (contacto) => ({ type: UPDATE_CONTACTO, contacto })
export const submittingContacto = (submitting) => ({ type: SUBMITTING_CONTACTO, submitting })
export const submitContactoError = (error) => ({ type: SUBMIT_CONTACTO_ERROR, error })
export const submitContactoSuccess = (success) => ({ type: SUBMIT_CONTACTO_SUCCESS, success })
export const resetContacto = () => ({ type: RESET_CONTACTO })

const initialState = {
  contacto: {
    id: null,
    nome: '',
    telefone: '',
    email: '',
    empresa: '',
    mensagem: ''
  },
  contactoIsSubmitting: false,
  contactoSubmissionErrored: false,
  contactoSubmissionSucceeded: false
}

export function mockSubmitContacto (contacto) {
  return (dispatch) => {
    dispatch(submittingContacto(true))
    dispatch(submitContactoError(false))
    dispatch(submitContactoSuccess(false))
    const id = generateUUID()
    contacto.id = id
    window.setTimeout(() => {
      const resetedContacto = {
        id: null,
        nome: '',
        telefone: '',
        email: '',
        empresa: '',
        mensagem: ''
      }
      dispatch({
        type: CONTACTO_SUBMITTED,
        contacto: resetedContacto
      })
      dispatch(submittingContacto(false))
      dispatch(submitContactoSuccess(true))
      // dispatch(submitContactoError(true))
    }, 2000)
  }
}

export function submitContacto (contacto, lang) {
  return (dispatch) => {
    dispatch(submittingContacto(true))
    dispatch(submitContactoError(false))
    dispatch(submitContactoSuccess(false))
    const id = generateUUID()
    contacto.id = id
    contacto.lang = !lang ? 'pt' : lang
    axios.put(`${host}/api/public/contacto/${id}`, contacto)
      .then(() => {
        const resetedContacto = {
          id: null,
          nome: '',
          telefone: '',
          email: '',
          empresa: '',
          mensagem: ''
        }
        dispatch({
          type: CONTACTO_SUBMITTED,
          contacto: resetedContacto
        })
        dispatch(submittingContacto(false))
        dispatch(submitContactoSuccess(true))
      })
      .catch(error => {
        console.log(error)
        dispatch(submittingContacto(false))
        dispatch(submitContactoError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_CONTACTO:
      return { ...state, contacto: action.contacto }
    case SUBMIT_CONTACTO_ERROR:
      return { ...state, contactoSubmissionErrored: action.error }
    case SUBMITTING_CONTACTO:
      return { ...state, contactoIsSubmitting: action.submitting }
    case CONTACTO_SUBMITTED:
      return { ...state, contacto: action.contacto }
    case SUBMIT_CONTACTO_SUCCESS:
      return { ...state, contactoSubmissionSucceeded: action.success }
    case RESET_CONTACTO:
      const newContacto = {
        id: null,
        nome: '',
        telefone: '',
        email: '',
        empresa: '',
        mensagem: ''
      }
      return { ...state, contacto: newContacto }
    default:
      return state
  }
}