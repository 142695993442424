import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './Login.module.css'

class Login extends Component {
  render () {
    const { itemsCount } = this.props
    return (
      <div className={styles.main}>
        {/* <div className={styles.button} onClick={() => this.login()}>Login</div> */}
        <Link to='/carro-consulta' className={styles.carroConsulta} >
          <div key={itemsCount} className={styles.redBall}>{itemsCount}</div>
        </Link>
      </div>
    )
  }

  login () {
    console.log('login')
    // kc.login()
  }
}

Login.propTypes = {
  itemsCount: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  itemsCount: state.carroConsulta.itemsCount
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
