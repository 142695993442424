import React, { Component } from 'react'

import styles from './ScrollTop.module.css'

export default class ScrollTop extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = { top: window.pageYOffset === 0 }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render () {
    if (this.state.top) {
      return (
        <div className={styles.scrollTop + ' ' + styles.atTop} onClick={this.scrollDown} />
      )
    }
    return (
      <div className={styles.scrollTop} onClick={this.scrollTop} />
    )
  }

  scrollTop (e) {
    e.preventDefault()
    e.stopPropagation()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  scrollDown (e) {
    e.preventDefault()
    e.stopPropagation()
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleScroll () {
    this.setState({ top: window.pageYOffset === 0 })
  }
}
