import axios from 'axios'
import { host, generateUUID } from '../request'
const APPEND_ITEM = 'bil/carroConsulta/APPEND_ITEM'
const INC_COUNT = 'bil/carroConsulta/INC_COUNT'
const DEC_COUNT = 'bil/carroConsulta/DEC_COUNT'
const REMOVE_ITEM = 'bil/carroConsulta/REMOVE_ITEM'
const RESET_CC = 'bil/carroConsulta/RESET_CC'
const SAVING_CONSULTA = 'bil/carroConsulta/SAVING_CONSULTA'
const CONSULTA_ERROR = 'bil/carroConsulta/CONSULTA_ERROR'
const CONSULTA_SUBMITTED = 'bil/carroConsulta/CONSULTA_SUBMITTED'
const SUBMIT_SUCCESS = 'bil/carroConsulta/SUBMIT_SUCCESS'
const UPDATE_CC = 'bil/carroConsulta/UPDATE_CC'

export const appendItem = (artigo) => ({ type: APPEND_ITEM, artigo })
export const incCount = () => ({ type: INC_COUNT })
export const decCount = () => ({ type: DEC_COUNT })
export const removeItem = (id) => ({ type: REMOVE_ITEM, id })
export const resetCarroConsulta = () => ({ type: RESET_CC })
export const savingConsulta = (saving) => ({ type: SAVING_CONSULTA, saving })
export const consultaError = (errored) => ({ type: CONSULTA_ERROR, errored })
export const submitSuccess = (success) => ({ type: SUBMIT_SUCCESS, success })
export const updateCC = (cc) => ({ type: UPDATE_CC, cc })

const initialState = {
  carroConsultaInProgress: {
    id: null,
    email: '',
    cliente: null,
    nomeCliente: '',
    dataPretendida: '',
    urgente: false,
    items: [],
    obs: '',
    nomeContacto: '',
    nifEmpresa: '',
    telefoneContacto: ''
  },
  itemsInProgress: [],
  itemsCount: 0,
  consultaIsSaving: false,
  consultaErrored: false,
  consultaSubmittedSuccess: false
}

export function mockSubmitCarro (carro, items) {
  return (dispatch) => {
    dispatch(savingConsulta(true))
    dispatch(consultaError(false))
    dispatch(submitSuccess(false))
    const id = generateUUID()
    carro.id = id
    carro.items = items
    window.setTimeout(() => {
      let newCarro = { ...carro }
      newCarro.id = null
      newCarro.dataPretendida = ''
      newCarro.items = []
      newCarro.urgente = false
      newCarro.obs = ''
      dispatch({
        type: CONSULTA_SUBMITTED,
        carro: newCarro
      })
      dispatch(savingConsulta(false))
      dispatch(submitSuccess(true))
      // dispatch(savingConsulta(false))
      // dispatch(consultaError(true))
    }, 1000)
  }
}

export function submitCarroConsulta (carro, items, lang) {
  return (dispatch) => {
    dispatch(savingConsulta(true))
    dispatch(consultaError(false))
    dispatch(submitSuccess(false))
    const id = generateUUID()
    carro.id = id
    carro.lang = !lang ? 'pt' : lang
    carro.items = items
    carro.items.forEach(item => {
      item.quantidade = !item.quantidade ? 0 : Number(item.quantidade)
    });
    axios.put(`${host}/api/public/addCarroConsulta/${id}`, carro)
      .then(() => {
        let newCarro = { ...carro }
        newCarro.id = null
        newCarro.dataPretendida = ''
        newCarro.items = []
        newCarro.urgente = false
        newCarro.obs = ''
        dispatch({
          type: CONSULTA_SUBMITTED,
          carro: newCarro
        })
        dispatch(savingConsulta(false))
        dispatch(consultaError(false))
        dispatch(submitSuccess(true))
      })
      .catch(error => {
        console.log(error)
        dispatch(savingConsulta(false))
        dispatch(consultaError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case APPEND_ITEM:
      const newItem = {
        id: action.artigo.id,
        quantidade: 1,
        descricaoProduto: action.artigo.nome,
        qualidadeProduto: null,
        dataPretendida: '',
        obs: null
      }
      const items = [...state.itemsInProgress]
      items.push(newItem)
      return { ...state, itemsInProgress: items, itemsCount: state.itemsCount + 1 }
    case INC_COUNT:
      return { ...state, itemsCount: state.itemsCount + 1 }
    case DEC_COUNT:
      return { ...state, itemsCount: state.itemsCount - 1 }
    case REMOVE_ITEM:
      let removeItems = [...state.itemsInProgress]
      removeItems = removeItemFromCarro(removeItems, action.id)
      return { ...state, itemsInProgress: removeItems, itemsCount: state.itemsCount - 1 }
    case RESET_CC:
      return { ...state, itemsInProgress: [], itemsCount: 0, carroConsultaInProgress: null }
    case SAVING_CONSULTA:
      return { ...state, consultaIsSaving: action.saving }
    case CONSULTA_ERROR:
      return { ...state, consultaErrored: action.errored }
    case CONSULTA_SUBMITTED:
      return { ...state, carroConsultaInProgress: action.carro, itemsInProgress: [], itemsCount: 0 }
    case SUBMIT_SUCCESS:
      return { ...state, consultaSubmittedSuccess: action.success }
    case UPDATE_CC:
      return { ...state, carroConsultaInProgress: action.cc }
    default:
      return state
  }
}

function removeItemFromCarro (items, id) {
  if (!items || !items.length) {
    return []
  }
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    if (element.id === id) {
      items.splice(index, 1)
      return items
    }
  }
  return items
}