import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ItemFileInput.module.css'
import { isOdd, readableBytes } from '../../utils'
import messages from '../../messages'

class ItemFileInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: []
    }
    this.fileSizeLimit = 2 * 1024 * 1024 // 1mb
  }

  render () {
    return (
      <div className={styles.main}>
        {this.buildUploadButton()}
        {this.buildFileList()}
      </div>
    )
  }

  buildUploadButton () {
    return (
      <div className={styles.fileUpload}>
        <span className={styles.anexoSpan}><FM id='carroConsulta.itemConsulta.files' defaultMessage='anexos' /></span>
        <input type='file' className={styles.upload}
          accept={'video/*,image/*,application/pdf'}
          multiple
          onChange={(e) => this.filesChanged(e)} />
        <span style={{ marginLeft: '10px' }}>{this.getFilesInfo()}</span>
      </div>
    )
  }

  getFilesInfo () {
    const size = this.getFilesSize()
    return `${readableBytes(size)} 
    ${messages[this.props.lang]['carroConsulta.itemConsulta.file.de']} 
    ${readableBytes(this.fileSizeLimit)} 
    ${messages[this.props.lang]['carroConsulta.itemConsulta.file.disponiveis']}`
  }

  getFilesSize () {
    let files = this.state.files
    let size = 0
    files.forEach(file => {
      size += file.file.length
    })
    return size
  }

  filesChanged (e) {
    e.preventDefault()
    const filesObj = e.target.files
    if (filesObj && filesObj.length > 0) {
      for (let index = 0; index < filesObj.length; index++) {
        const file = filesObj[index]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const mediaFile = {
            name: file.name,
            type: file.type,
            file: e.target.result
          }
          this.appendFile(mediaFile)
        }
      }
    }
  }

  appendFile (file) {
    let files = this.state.files
    files.push(file)
    this.setState({ files: files })
  }

  buildFileList () {
    const files = this.state.files
    if (!files || !files.length) {
      return null
    }
    const oddStyle = { backgroundColor: '#e2e2e2' }
    return (
      <div className={styles.filesCont}>
        {files.map((file, index) => (
          <div key={index} className={styles.fileCont} style={isOdd(index) ? oddStyle : null}>
            <div className={styles.fileName}>{file.name}</div>
            <div className={styles.removeButton} onClick={() => this.removeFile(index)} />
          </div>
        ))}
      </div>
    )
  }

  removeFile (index) {
    let files = [...this.state.files]
    files.splice(index, 1)
    this.setState({ files: files })
  }
}

ItemFileInput.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ItemFileInput)
