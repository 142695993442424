import axios from 'axios'
import { host } from '../request'
const SET_ITEMS_MENU = 'bil/menuFamilias/SET_ITEMS_MENU'
const SELECT_ITEM = 'bil/menuFamilias/SELECT_ITEM'
const SELECT_TOP_LEVEL = 'bil/menuFamilias/SELECT_TOP_LEVEL'
const ITEMS_LOADING = 'bil/menuFamilias/ITEMS_LOADING'
const ITEMS_LOADING_ERROR = 'bil/menuFamilias/ITEMS_LOADING_ERROR'

const initialState = {
  items: [
    {
      id: '1',
      nome: 'Redes',
      children: [
        {
          id: '2',
          nome: 'Rede Ondulada',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
          children: [
            {
              id: '3',
              nome: 'Tremida Dupla',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            },
            {
              id: '4',
              nome: 'Tremida Simples',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            }
          ]
        },
        {
          id: '5',
          nome: 'Eletrosoldadas',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
          children: [
            {
              id: '6',
              nome: 'Arame cru',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            },
            {
              id: '7',
              nome: 'Arame Zincado Duro',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            }
          ]
        }
      ]
    },
    {
      id: '8',
      nome: 'Tubos',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
      children: [
        {
          id: '9',
          nome: 'Tubos Estruturais',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
          children: [
            {
              id: '10',
              nome: 'Tubos Quadrados (EN10219)',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            },
            {
              id: '11',
              nome: 'Tubos Redondos (EN10219)',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            },
            {
              id: '12',
              nome: 'Tubos Retangulares (EN10219)',
              descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
            }
          ]
        }
      ]
    },
    {
      id: '13',
      nome: 'Chapa',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
      children: [
        {
          id: '14',
          nome: 'Chapa formatada e em Rolos',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
        },
        {
          id: '15',
          nome: 'Chapa com Relevo',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
        },
        {
          id: '16',
          nome: 'Chapas Perfiladas',
          descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis sed odio. Suspendisse in est ante in nibh mauris cursus mattis molestie. Quam id leo in vitae turpis massa. Vestibulum lectus mauris ultrices eros. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Scelerisque felis imperdiet proin fermentum leo vel. Ac auctor augue mauris augue neque.',
        }
      ]
    },
  ],
  topLevelSelected: null, // primeiro nível seleccionado
  selectedItems: [],
  selectedItem: null,
  itemsAreLoading: false,
  itemsLoadingErrored: false
}

export const setItems = (items) => ({ type: SET_ITEMS_MENU, items })
export const selectItem = (item) => ({ type: SELECT_ITEM, item })
export const selectTopLevel = (item) => ({ type: SELECT_TOP_LEVEL, item })
export const itemsLoading = (loading) => ({ type: ITEMS_LOADING, loading })
export const itemsLoadingError = (errored) => ({ type: ITEMS_LOADING_ERROR, errored })

export function fetchMenuItems (lang) {
  return (dispatch) => {
    dispatch(itemsLoading(true))
    dispatch(itemsLoadingError(false))
    axios.get(host + '/api/public/itemsMenuTree?lang=' + lang)
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: SET_ITEMS_MENU,
          items: posts
        })
        dispatch(itemsLoading(false))
      })
      .catch(error => {
        console.log(`error`, error)
        dispatch(itemsLoading(false))
        dispatch(itemsLoadingError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_ITEMS_MENU:
      return { ...state, items: action.items }
    case SELECT_TOP_LEVEL:
      return { ...state, topLevelSelected: action.item, selectedItems: [action.item], selectedItem: action.item }
    case ITEMS_LOADING:
      return { ...state, itemsAreLoading: action.loading }
    case ITEMS_LOADING_ERROR:
      return { ...state, itemsLoadingErrored: action.errored }
    default:
      return state
  }
}
