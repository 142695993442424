import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'

import styles from './SabiaQue.module.css'

export default class SabiaQue extends Component {
  render () {
    return (
      <div className={styles.body}>
        <div className={styles.title}><FM id='sabiaQue.title' defaultMessage='sabia que?' /></div>
        <div className={styles.message}><FM id='sabiaQue.message' defaultMessage='mensagem' /></div>
      </div>
    )
  }
}
