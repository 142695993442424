import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'

import styles from './GamaProdutos.module.css'

export default class GamaProdutos extends Component {
  render () {
    return (
      <div className={styles.main}>
        <h3><FM id='gamaProdutos.title' defaultMessage='Gama de produtos' /></h3>
        <div className={styles.text}><FM id='gamaProdutos.subtitle' defaultMessage='CONHEÇA O MUNDO BIL POR DENTRO' /></div>
        <div className={styles.text}><FM id='gamaProdutos.text1' defaultMessage='Verifique' />&nbsp;
          <a href='docs/product_list.pdf' target='_blank'><FM id='gamaProdutos.linkText' defaultMessage='aqui' /></a>
          &nbsp;<FM id='gamaProdutos.text2' defaultMessage='a gama comercial e Fabricada da nossa empresa' />
        </div>
      </div>
    )
  }
}
