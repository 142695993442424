import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './CarroConsulta.module.css'
import messages from '../../messages'
import ItemsConsulta from './ItemsConsulta'
import { updateCC, mockSubmitCarro, submitCarroConsulta } from '../../modules/carroConsulta'
import { setCarroConsultaVisited, setCarroConsultaHelpDisplayed } from '../../modules/geral'
import PageHeader from '../PageHeader/PageHeader'
import { setStaticMenu } from '../../modules/geral'

class CarroConsulta extends Component {
  constructor(props) {
    super(props)
    this.empresaRef = React.createRef()
    this.nifRef = React.createRef()
    this.contactoRef = React.createRef()
    this.telefRef = React.createRef()
    this.emailRef = React.createRef()
    this.dataEntregaRef = React.createRef()
    this.urgenteRef = React.createRef()
    this.obsRef = React.createRef()
    const { match: { path }, carroConsulta, carroConsultaVisited } = props
    this.props.setStaticMenu(path)
    if (!carroConsultaVisited) {
      props.setCarroConsultaVisited(true)
    }
    this.state = {
      email: carroConsulta.email,
      nomeCliente: carroConsulta.nomeCliente,
      dataPretendida: carroConsulta.dataPretendida,
      urgente: carroConsulta.urgente,
      obs: carroConsulta.obs,
      nomeContacto: carroConsulta.nomeContacto,
      nifEmpresa: carroConsulta.nifEmpresa,
      telefoneContacto: carroConsulta.telefoneContacto
    }
  }

  componentWillUnmount () {
    this.props.setCarroConsultaHelpDisplayed(true)
  }

  render () {
    const { lang, carroConsulta, itemsInProgress } = this.props
    const submitEnabled = (itemsInProgress && itemsInProgress.length > 0) ? true : false

    return (
      <div>
        <PageHeader
          title={'carroConsulta.title'}
          subtitle={null}
          shadowTitle
          formname={'carroConsulta'} />
        {/* <h1><FM id='carroConsulta.title' defaultMessage='carro de consulta' /></h1> */}
        <div className={styles.body}>
          <form onSubmit={(e) => this.submitForm(e)}>
            {this.buildContactInfo(lang, carroConsulta)}
            {this.buildCarroConsultaData(lang, carroConsulta)}
            <ItemsConsulta />
            <input disabled={!submitEnabled} type='submit'
              className={styles.button + (!submitEnabled ? ' ' + styles.disabled : '')} value='Enviar consulta' />
          </form>
        </div>
      </div>
    )
  }

  buildContactInfo (lang, cc) {
    return (
      <>
        <h3><FM id='carroConsulta.contact.title' defaultMessage='Dados de contacto' /></h3>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.empresa' defaultMessage='empresa' />&nbsp;*</label>
          <input ref={this.empresaRef} className={styles.input} id='empresa'
            name='empresa' type='text' required maxLength='255'
            onInvalid={() => this.empresaRef.current.setCustomValidity(messages[lang]['carroConsulta.empresaErr'])}
            onInput={() => this.empresaRef.current.setCustomValidity('')}
            value={this.state.nomeCliente}
            onChange={this.nomeClienteUpdate} />
        </div>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.nif' defaultMessage='nif' />&nbsp;*</label>
          <input ref={this.nifRef} className={styles.input + ' ' + styles.narrow} id='nif'
            name='nif' type='text' required maxLength='15'
            onInvalid={() => this.nifRef.current.setCustomValidity(messages[lang]['carroConsulta.nifErr'])}
            onInput={() => this.nifRef.current.setCustomValidity('')}
            value={this.state.nifEmpresa}
            onChange={this.nifEmpresaUpdate} />
        </div>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.nomeContacto' defaultMessage='Contacto' />&nbsp;*</label>
          <input ref={this.contactoRef} className={styles.input} id='contacto'
            name='contacto' type='text' required maxLength='255'
            onInvalid={() => this.contactoRef.current.setCustomValidity(messages[lang]['carroConsulta.nomeContactoErr'])}
            onInput={() => this.contactoRef.current.setCustomValidity('')}
            value={this.state.nomeContacto}
            onChange={this.nomeContactoUpdate} />
        </div>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.telefone' defaultMessage='Telefone' />&nbsp;*</label>
          <input ref={this.telefRef} className={styles.input + ' ' + styles.narrow} id='telefone'
            name='telefone' type='text' required maxLength='25'
            onInvalid={() => this.telefRef.current.setCustomValidity(messages[lang]['carroConsulta.telefoneErr'])}
            onInput={() => this.telefRef.current.setCustomValidity('')}
            value={this.state.telefoneContacto}
            onChange={this.telefoneContactoUpdate} />
        </div>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.email' defaultMessage='email' />&nbsp;*</label>
          <input ref={this.emailRef} className={styles.input} id='email'
            name='email' type='text' required maxLength='255'
            onInvalid={() => this.emailRef.current.setCustomValidity(messages[lang]['carroConsulta.emailErr'])}
            onInput={() => this.emailRef.current.setCustomValidity('')}
            value={this.state.email}
            onChange={this.emailUpdate} />
        </div>
      </>
    )
  }

  buildCarroConsultaData (lang, cc) {
    return (
      <>
        <h3><FM id='carroConsulta.requestData.title' defaultMessage='Dados do pedido' /></h3>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.dataPretendida' defaultMessage='dataPretendida' /></label>
          <input ref={this.dataEntregaRef} className={styles.input + ' ' + styles.narrow} id='dataPretendida'
            name='dataPretendida' type='date'
            value={this.state.dataPretendida}
            onChange={this.dataPretendidaUpdate} />
        </div>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}><FM id='carroConsulta.urgente' defaultMessage='urgente' /></label>
          <input ref={this.urgenteRef} className={styles.input + ' ' + styles.narrow} id='urgente'
            name='urgente' type='checkbox'
            checked={this.state.urgente}
            onChange={this.urgenteUpdate} />
        </div>
        <div className={styles.textareaWrapper}>
          <label className={styles.label}><FM id='carroConsulta.obs' defaultMessage='observações' /></label>
          <textarea ref={this.obsRef} className={styles.textarea} id='obs'
            name='obs' maxLength='5000'
            value={this.state.obs}
            onChange={this.obsUpdate} />
        </div>
      </>
    )
  }

  nomeClienteUpdate = (e) => {
    this.setState({ nomeCliente: e.target.value })
    this.props.carroConsulta.nomeCliente = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  nifEmpresaUpdate = (e) => {
    this.setState({ nifEmpresa: e.target.value })
    this.props.carroConsulta.nifEmpresa = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  nomeContactoUpdate = (e) => {
    this.setState({ nomeContacto: e.target.value })
    this.props.carroConsulta.nomeContacto = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  telefoneContactoUpdate = (e) => {
    this.setState({ telefoneContacto: e.target.value })
    this.props.carroConsulta.telefoneContacto = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  emailUpdate = (e) => {
    this.setState({ email: e.target.value })
    this.props.carroConsulta.email = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  dataPretendidaUpdate = (e) => {
    this.setState({ dataPretendida: e.target.value })
    this.props.carroConsulta.dataPretendida = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  urgenteUpdate = (e) => {
    this.setState({ urgente: e.target.checked })
    this.props.carroConsulta.urgente = e.target.checked
    this.props.updateCC(this.props.carroConsulta)
  }

  obsUpdate = (e) => {
    this.setState({ obs: e.target.value })
    this.props.carroConsulta.obs = e.target.value
    this.props.updateCC(this.props.carroConsulta)
  }

  submitForm (e) {
    e.preventDefault()
    console.log('submit')
    const { carroConsulta, itemsInProgress, lang } = this.props
    // this.props.mockSubmitCarro(carroConsulta, itemsInProgress)
    this.props.submitCarroConsulta(carroConsulta, itemsInProgress, lang)
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    this.setState({
      dataPretendida: '',
      urgente: false,
      obs: ''
    })
  }
}

CarroConsulta.propTypes = {
  lang: PropTypes.string.isRequired,
  carroConsulta: PropTypes.object.isRequired,
  updateCC: PropTypes.func.isRequired,
  itemsInProgress: PropTypes.array,
  mockSubmitCarro: PropTypes.func.isRequired,
  setCarroConsultaVisited: PropTypes.func.isRequired,
  setCarroConsultaHelpDisplayed: PropTypes.func.isRequired,
  submitCarroConsulta: PropTypes.func.isRequired,
  setStaticMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang,
  carroConsulta: state.carroConsulta.carroConsultaInProgress,
  itemsInProgress: state.carroConsulta.itemsInProgress,
  consultaErrored: state.carroConsulta.consultaErrored,
  carroConsultaVisited: state.geral.carroConsultaVisited
})

const mapDispatchToProps = {
  updateCC, mockSubmitCarro, setCarroConsultaVisited,
  setCarroConsultaHelpDisplayed, submitCarroConsulta, setStaticMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(CarroConsulta)
