import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'

import styles from './MenuInst.module.css'

class MenuInst extends Component {
  constructor(props) {
    super(props)
    this.menus = [
      '/carro-consulta',
      '/artigos',
      '/qualidades',
      '/about-us',
      '/project',
      '/contact',
      '/entregas-fast',
    ]
  }
  render () {
    const { staticMenu } = this.props
    console.log(`staticMenu`, staticMenu)
    return (
      <div className={styles.main}>
        <Link to={this.menus[0]}
          className={styles.menuItem + ' ' + (staticMenu === this.menus[0] ? styles.selected : '')}>
          <FM id='menuInst.listaConsulta' defaultMessage='Lista de consulta' />
        </Link>
        <Link to={this.menus[1]} className={styles.menuItem + ' ' + (staticMenu === this.menus[1] ? styles.selected : '')}>
          <FM id='menuInst.artigos' defaultMessage='artigos' />
        </Link>
        <Link to={this.menus[2]} className={styles.menuItem + ' ' + (staticMenu === this.menus[2] ? styles.selected : '')}>
          <FM id='menuInst.qualidades' defaultMessage='Qualidades' />
        </Link>
        <Link to={this.menus[3]} className={styles.menuItem + ' ' + (staticMenu === this.menus[3] ? styles.selected : '')}>
          <FM id='menuInst.about' defaultMessage='Sobre nós' />
        </Link>
        <Link to={this.menus[4]} className={styles.menuItem + ' ' + (staticMenu === this.menus[4] ? styles.selected : '')}>
          <FM id='menuInst.projeto' defaultMessage='Projeto' />
        </Link>
        {/* <div className={styles.menuItem}><FM id='menuInst.novidades' defaultMessage='Novidades' /></div> */}
        <Link to={this.menus[5]} className={styles.menuItem + ' ' + (staticMenu === this.menus[5] ? styles.selected : '')}>
          <FM id='menuInst.contatos' defaultMessage='Contatos' />
        </Link>
        <Link to={this.menus[6]} className={styles.menuItem + ' ' + (staticMenu === this.menus[6] ? styles.selected : '')}>
          <FM id='menuInst.entregasFast' defaultMessage='Entregas FAST' />
        </Link>
      </div>
    )
  }
}

MenuInst.propTypes = {
  staticMenu: PropTypes.string
}

const mapStateToProps = state => ({
  staticMenu: state.geral.staticMenu
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MenuInst)
