import React, { Component } from 'react'

import styles from './Project.module.css'

export default class ProjectPt extends Component {
  render () {
    return (
      <div className={styles.formContainer}>
        <p><span>Designação do projeto: </span>+QI | Qualificação e Internacionalização da BIL</p>
        <p><span>Código do projeto: </span><nobr>POCI-02-0752-FEDER-021535</nobr></p>
        <p><span>Objetivo principal: </span>Capacitação das médias empresas</p>
        <p>Região de intervenção: <span>Norte</span></p>
        <p>Entidade beneficiária: <span>BAPTISTA &amp; IRMÃO S.A.</span></p>
        <p>Data de aprovação | <nobr>16-09-2016</nobr></p>
        <p>Data de início | <nobr>16-05-2016</nobr></p>
        <p>Data de conclusão | <nobr>15-05-2018</nobr></p>
        <p>Custo total elegível | 146.650,23 EUR</p>
        <p>Apoio financeiro da União Europeia | FEDER – 66.477,78 EUR</p>
        <p>Com o presente projeto de internacionalização, a Baptista &amp; Irmão, S.A. pretende adotar uma estratégia de extensão de mercado visando a entrada em novos mercados externos, nomeadamente Moçambique, Cabo verde, são Tomé e Príncipe e Guiné Bissau e consolidando a sua presença no mercado angolano.</p>
        <p>Atendendo à sua visão, missão e abordagens visadas ao nível internacional, a entidade definiu, entre outros, os seguintes objetivos:</p>
        <p><span>-</span><span>Aumento do Volume de Negócios Internacional e Total através da aposta em novos mercados externos;</span></p>
        <p><span>-</span><span>Apostar na qualificação da estrutura de recursos humanos da empresa;</span></p>
        <p><span>-</span><span>Inovar ao nível do marketing e reforçar a imagem da marca e da empresa.</span></p>
        <p>A fim de pôr em prática a abordagem visada ao nível da internacionalização e alcançar os objetivos definidos, visa executar as seguintes ações:</p>
        <p><span>-</span><span>Conhecimento de mercados externos: participação como expositor em feiras do setor de atividade da empresa;</span></p>
        <p><span>-</span><span>Prospeção e presença em mercados internacionais: Realização de viagens de prospeção aos </span><nobr>mercados-alvo</nobr> (Moçambique, Cabo Verde, Angola e Guiné), potenciando a angariação de novos/potenciais clientes internacionais;</p>
        <p><span>-</span><span>Marketing internacional: Desenvolvimento de catálogos e vídeo promocional, bem como realização de um estudo de viabilidade económica para divulgação dos seus serviços nos mercados externos e verificação das linhas de atuação;</span></p>
        <p><span>-</span><span>Conhecimento de mercados externos (Feiras/Exposições): Participação em feiras internacionais para promover a notoriedade da marca e estabelecer contactos internacionais;</span></p>
        <p><span>-</span><span>Introdução de novo método de organização nas práticas comerciais ou nas relações externas: Contratação de dois novos recursos humanos qualificados, o primeiro em Relações Internacionais e o segundo em Marketing.</span></p>
        <p>Com o presente projeto, a entidade visa alcançar um Volume de Negócios Internacional no ano pós projeto de € 5.038.695,81 face a um volume no <nobr>pré-projeto</nobr> de € 1.478.870,76.</p>
      </div>
    )
  }
}
