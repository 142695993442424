import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ItemConsulta.module.css'
import { removeItem } from '../../modules/carroConsulta'
import { enableArtigo } from '../../modules/artigos'
import ItemFileInput from './ItemFileInput'

class ItemConsulta extends Component {
  constructor(props) {
    super(props)
    this.qtdRef = React.createRef()
    this.qualidadeProdutoRef = React.createRef()
    this.dataPretendidaRef = React.createRef()
    this.obsRef = React.createRef()
  }
  render () {
    const { item } = this.props
    console.log('ItemConsulta:', item)
    if (!item.expanded)
      return this.drawCollapsed(item)
    else
      return this.drawExpanded(item)
  }

  drawCollapsed (item) {
    return (
      <tr className={styles.body + ' ' + styles.tr}>
        <td className={styles.nome}>{item.descricaoProduto}</td>
        <td><input ref={this.qtdRef} className={styles.input} type='text' required maxLength='10'
          onChange={() => this.setQuantidade(item)} value={item.quantidade} /></td>
        <td className={styles.expandCollapseContainer}>
          <div className={styles.expand} onClick={() => this.toggleExpanded(item)} />
        </td>
        <td className={styles.actionItem} onClick={() => this.removeItem(item.id)}>
          <FM id='artigo.removeShort' defaultMessage='remover' />
        </td>
      </tr>
    )
  }

  drawExpanded (item) {
    return (
      <>
        <tr className={styles.body}>
          <td className={styles.nome}>{item.descricaoProduto}</td>
          <td></td>
          <td className={styles.expandCollapseContainer}>
            <div className={styles.collapse} onClick={() => this.toggleExpanded(item)} />
          </td>
          <td className={styles.actionItem} onClick={() => this.removeItem(item.id)}>
            <FM id='artigo.removeShort' defaultMessage='remover' />
          </td>
        </tr>
        <tr className={styles.body + ' ' + styles.tr}>
          <td colSpan='2' className={styles.column}>
            <div className={styles.columnDataCont}>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='carroConsulta.itemConsulta.unidades' defaultMessage='unidades' /></label>
                <input ref={this.qtdRef} className={styles.input}
                  id='quantidade'
                  name='quantidade' type='number'
                  value={item.quantidade} onChange={() => this.setQuantidade(item)}
                  required maxLength='10' />
              </div>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='carroConsulta.itemConsulta.qualidade' defaultMessage='qualidade' /></label>
                <input ref={this.qualidadeProdutoRef} className={styles.input + ' ' + styles.inputWide}
                  id='qualidadeProduto'
                  name='qualidadeProduto' type='text'
                  value={item.qualidadeProduto} onChange={() => this.setQualidadeProduto(item)} maxLength='255' />
              </div>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='carroConsulta.itemConsulta.obs' defaultMessage='observações' /></label>
                <input ref={this.obsRef} className={styles.input + ' ' + styles.inputWide}
                  id='obs'
                  name='obs' type='text'
                  value={item.obs} onChange={() => this.setObs(item)} maxLength='255' />
              </div>
              {this.drawFileInput(item)}
            </div>
          </td>
        </tr>
      </>
    )
  }

  drawFileInput (item) {
    console.log(`item`, item)
    if (item.id) {
      return null
    }
    return (
      <div className={styles.fieldWrapper}>
        {/* <label className={styles.label}>Ficheiros com imagens</label> */}
        <ItemFileInput item={item} />
      </div>
    )
  }

  removeItem (id) {
    this.props.enableArtigo(id)
    this.props.removeItem(id)
  }

  setQuantidade (item) {
    item.quantidade = this.qtdRef.current.value
    this.forceUpdate()
  }

  setQualidadeProduto (item) {
    item.qualidadeProduto = this.qualidadeProdutoRef.current.value
    this.forceUpdate()
  }

  setDataPretendida (item) {
    item.dataPretendida = this.dataPretendidaRef.current.value
    this.forceUpdate()
  }

  setObs (item) {
    item.obs = this.obsRef.current.value
    this.forceUpdate()
  }

  toggleExpanded (item) {
    item.expanded = !item.expanded
    this.forceUpdate()
  }
}

ItemConsulta.propTypes = {
  removeItem: PropTypes.func.isRequired,
  enableArtigo: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = { removeItem, enableArtigo }

export default connect(mapStateToProps, mapDispatchToProps)(ItemConsulta)
