import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './ServicoEntregas.module.css'
import PageHeader from '../PageHeader/PageHeader'
import { setStaticMenu } from '../../modules/geral'

class ServicoEntregas extends Component {
  constructor(props) {
    super(props)
    const { match: { path } } = props
    this.props.setStaticMenu(path)
  }

  render () {
    const { lang } = this.props
    return (
      <div className={styles.main}>
        <PageHeader
          title={'entregas.header.title'}
          subtitle={null}
          shadowTitle
          formname={'entregas'} />
        {
          lang === 'pt'
            ? this.pt()
            : this.en()
        }
      </div>
    )
  }
  pt () {
    return (
      <div className={styles.formContainer}>
        <h2>BIL FAST</h2>
        <p>O nosso serviço logístico apesar da nossa tipologia de produtos complexa consegue entregar diariamente entre 65 a 75 clientes na zona demarcada no mapa. No entanto, fretamos camiões para fora da zona assinalada mediante acordo de preço com o nosso cliente.</p>
        <p>O elevado empenho de toda a equipa logística permite entregar no dia seguinte 85% das encomendas rececionadas no dia anterior. O remanescente é entregue normalmente em 48h00.</p>
        <p>Todas as encomendas que têm datas de produção ou datas especificas, são acompanhadas pelo serviço logístico com a finalidade de podermos manter informados os nossos clientes da data de entrega. As nossas falhas constituem o principal elemento de aprendizagem de como proceder no futuro e voltar a não errar.</p>
        <p>O crescente grau de exigência dos nossos clientes ao nível de entrega obriga-nos a criar procedimentos diários para podermos fazer face às solicitações dos nossos clientes.</p>
        <div className={styles.mapa} />
        <h2>Exportação</h2>
        <p>Ao nível de Exportação fretamos camiões, estrados ou serviços de contentorização para fazer chegar os produtos aos nossos clientes no melhor estado e o mais rápido possível.</p>
        <p>A crescente procura dos nossos produtos mais inovadores idealizados pelo nosso Departamento I+D têm permitido criar uma dinâmica de Exportação que queremos continuar a recrudescer nos próximos anos, sendo extremamente importante o cumprimento dos prazos de entrega.</p>
        <p>O fator capacidade de entrega ultrapassa todos os fatores incluindo o preço dos produtos. Entregar bem e rápido diminui tempos de produção e os custos finais de projeto.</p>
        <div className={styles.line} />
        <p className={styles.bold}>Não olhe só para o Preço. Quer parar a sua produção? Seja nosso parceiro  BIL FAST é a sua SOLUÇÃO.</p>
      </div>
    )
  }

  en () {
    return (
      <div className={styles.formContainer}>
        <h2>BIL FAST DELIVERIES SERVICE</h2>
        <p>Our logistical servisse, despite our complex product typology, manages to deliver 65 to 75 customers daily in the área marked on the map. However, we charter trucks outsider the indicated area upon price agreement with our cliente.</p>
        <p>The high commitment of the entire logistics team alows 85% of orders received dayly to be delivered in the next day. The remainder is normaliy delivered within 48h00.</p>
        <p>All orders that have production dates or specific dates are accompanied by the logistic service in order to keep our customers informed of the delivery date. Our failures are the main element of learning how to proceed in the future and not make the same mistakes again.</p>
        <p>The increasing level of demand from our customers in terms of delivery, forces ud to create daily procedures so that we can meet our customers request.</p>
        <div className={styles.mapaEn} />
        <h2>Exportation</h2>
        <p>At the Export level, we charter trucks, pallets or container services to deliver products to our customers in the best condition and as quickly as possible.</p>
        <p>The growing demand for our most innovative products created by our R&amp;D Department has allowed us to create na export dynamic that we want to continue to grow in the coming years. Our compromise with deadline deliveries its one of the most importante points in our company.</p>
        <p>The deliverability factor surpasses all factor including product price. Delivering well and quickly cuts lead times and final project costs.</p>
        <div className={styles.line} />
        <p className={styles.bold}>Don´t just look at the Price. Do you want to stop your production? Become our partner. BIL FAST is your SOLUTION.</p>
      </div>
    )
  }
}

ServicoEntregas.propTypes = {
  lang: PropTypes.string.isRequired,
  setStaticMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = { setStaticMenu }

export default connect(mapStateToProps, mapDispatchToProps)(ServicoEntregas)
