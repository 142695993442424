import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import styles from './Familia.module.css'
import MenuHeader from '../MenuFamilias/MenuHeader'
import ArtigosFilter from '../Artigos/ArtigosFilter'
import ArtigoCustom from '../Artigos/ArtigoCustom'
import ArtigosLista from '../Artigos/ArtigosLista'
import { setCurrentFamiliaId } from '../../modules/artigos'
import { fetchFamilia } from '../../modules/familias'
import spinner from '../../css/Spinner.module.css'

class Familia extends Component {
  constructor(props) {
    super(props)
    const { lang, match: { params } } = props
    console.log(params)
    // props.setCurrentFamiliaId(params.id)
    props.setCurrentFamiliaId('01')
    console.log('construtor')
    props.fetchFamilia(lang, params.id)
  }

  render () {
    const { currentFamilia, familiaIsLoading } = this.props
    if (familiaIsLoading || !currentFamilia) {
      return (
        <div className={styles.main}>
          <MenuHeader
            title={'XXXXXXXXXXXXXXXXXXX'}
          />
          <div className={styles.body}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    return (
      <div className={styles.main}>
        <MenuHeader
          title={currentFamilia.nome}
        />
        <div className={styles.body}>
          {this.drawDescricao(currentFamilia)}
          {this.drawInfoTecnica(currentFamilia)}
          {this.drawQualidades(currentFamilia)}
          <Accordion>
            <Card>
              <Card.Header>
                <div className={styles.cardHeader}>
                  <div className={styles.headerText}>Artigos</div>
                  <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                    <div className={styles.headerText}>expandir</div>
                  </Accordion.Toggle>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey='0'>
                <Card.Body>
                  <ArtigosFilter />
                  <ArtigoCustom />
                  <ArtigosLista />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    )
  }

  drawDescricao (familia) {
    if (!familia.descricao) {
      return null
    }
    return (
      <>
        <h2><FM id='familia.descricao' defaultMessage='Descrição' /></h2>
        <div className={styles.texto}>{familia.descricao}</div>
      </>
    )
  }

  drawInfoTecnica (familia) {
    if (!familia.infoTecnica) {
      return null
    }
    return (
      <>
        <h2><FM id='familia.infoTecnica' defaultMessage='Info técnica' /></h2>
        <div className={styles.texto}>{familia.infoTecnica}</div>
      </>
    )
  }

  drawQualidades (familia) {
    if (!familia.qualidades || !familia.qualidades.length) {
      return null
    }
    return (
      <>
        <h2><FM id='familia.qualidades' defaultMessage='Qualidades' /></h2>
        <div className={styles.qualidadesGrid}>
          {
            familia.qualidades.map((qual, index) => {
              return (
                <div key={index} className={styles.qualidade}>{qual}</div>
              )
            })
          }
        </div>
      </>
    )
  }
}

Familia.propTypes = {
  setCurrentFamiliaId: PropTypes.func.isRequired,
  currentFamilia: PropTypes.object,
  fetchFamilia: PropTypes.func.isRequired,
  familiaIsLoading: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  currentFamilia: state.familias.currentFamilia,
  familiaIsLoading: state.familias.familiaIsLoading,
  lang: state.language.lang
})

const mapDispatchToProps = { setCurrentFamiliaId, fetchFamilia }

export default connect(mapStateToProps, mapDispatchToProps)(Familia)
