import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ItemsConsulta.module.css'
import ItemConsulta from './ItemConsulta'

class ItemsConsulta extends Component {
  render () {
    const { itemsInProgress } = this.props
    if (!itemsInProgress || !itemsInProgress.length) {
      return null
    }
    return (
      <div className={styles.body}>
        <h3><FM id='carroConsulta.artigos.title' defaultMessage='Artigos' /></h3>
        {this.buildTabela(itemsInProgress)}
      </div>
    )
  }

  buildTabela (items) {
    if (!items || !items.length) {
      return null
    }
    return (
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            <th className={styles.th}><FM id='carroConsulta.artigos.artigo' defaultMessage='Artigo' /></th>
            <th className={styles.th + ' ' + styles.thInput}><FM id='carroConsulta.artigos.unidades' defaultMessage='Unidades' /></th>
            <th className={styles.th30}></th>
            <th className={styles.th30}></th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, index) => (
              <ItemConsulta key={index} item={item} />
            ))
          }
        </tbody>
      </table>
    )
  }
}

ItemsConsulta.propTypes = {
  itemsInProgress: PropTypes.array
}

const mapStateToProps = state => ({
  itemsInProgress: state.carroConsulta.itemsInProgress
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsConsulta)
