import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import styles from './SubMenu.module.css'

export default class SubMenu extends Component {
  render () {
    const { item } = this.props
    return (
      <div className={styles.body}>
        {this.drawFamilias(item)}
      </div>
    )
  }

  drawFamilias (item) {
    if (!item.children || !item.children.length) {
      return null
    }
    return (
      <>
        {
          item.children.map(item => {
            const familiaId = !item.familia ? null : item.familia.id
            const path = !item.children || !item.children.length ? '/familia/' + familiaId : '/menu-familia/' + item.id
            return (
              <div key={item.id} className={styles.row}>
                <Link to={path}
                  className={styles.menuItem}>
                  {item.nome}
                </Link>
                <div className={styles.descricao}>{item.descricao}</div>
              </div>
            )
          })
        }
      </>
    )
  }
}
