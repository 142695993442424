import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ArtigoCustom.module.css'
import messages from '../../messages'
import { appendItem } from '../../modules/carroConsulta'
import { setDisplayNewArtigoCustomAdded } from '../../modules/geral'

class ArtigoCustom extends Component {
  constructor(props) {
    super(props)
    this.descricaoRef = React.createRef()
  }
  render () {
    const { lang } = this.props
    return (
      <div className={styles.body}>
        <h3><FM id='artigoCustom.title' defaultMessage='Artigo personalizado' /></h3>
        {/* <div><FM id='artigoCustom.help' defaultMessage='adicione diretamente' /></div> */}
        <form className={styles.form} onSubmit={(e) => this.submitForm(e)}>
          <div className={styles.fieldWrapper}>
            <label className={styles.label}><FM id='artigoCustom.help' defaultMessage='Descrição do artigo' /></label>
            <div className={styles.inputWidthSubmit}>
              <input ref={this.descricaoRef} className={styles.input} id='descricao' name='descricao' type='text' required maxLength='256'
                onInvalid={() => this.descricaoRef.current.setCustomValidity(messages[lang]['artigoCustom.descricaoErr'])}
                onInput={() => this.descricaoRef.current.setCustomValidity('')} />
              <input type='submit' className={styles.button} value={messages[lang]['artigoCustom.addAction']} />
            </div>
          </div>
        </form>
      </div>
    )
  }

  submitForm (e) {
    e.preventDefault()
    this.props.appendItem({
      id: null,
      nome: this.descricaoRef.current.value
    })
    this.descricaoRef.current.value = ''
    this.props.setDisplayNewArtigoCustomAdded(true)
  }
}

ArtigoCustom.propTypes = {
  lang: PropTypes.string.isRequired,
  artigoCustom: PropTypes.object,
  filtrosQualidade: PropTypes.array,
  appendItem: PropTypes.func.isRequired,
  setDisplayNewArtigoCustomAdded: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang,
  artigoCustom: state.artigos.artigoCustom,
  filtrosQualidade: state.artigos.filtrosQualidade
})

const mapDispatchToProps = { appendItem, setDisplayNewArtigoCustomAdded }

export default connect(mapStateToProps, mapDispatchToProps)(ArtigoCustom)
