import React, { Component } from 'react'

import styles from './GoogleMap.module.css'

export default class GoogleMap extends Component {
  render () {
    const { title } = this.props
    return (
      <div className={styles.mapa}>
        <iframe title={title}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6034.918881482246!2d-8.611188228165947!3d40.861790499984984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23842d79caf94b%3A0x7b8940dd4e456abc!2sBatista+%26+Irm%C3%A3o+Sa!5e0!3m2!1sen!2spt!4v1530092947182"
          width='100%' height='400' frameBorder='0' style={{ 'border': 0 }} allowFullScreen></iframe>
      </div>
    )
  }
}
