import axios from 'axios'
import { host } from '../request'
const SEARCH_ARTIGOS = 'bil/search/SEARCH_ARTIGOS'
const SET_CURRENT_PAGE = 'bil/search/SET_CURRENT_PAGE'
const SET_TOTAL_PAGES = 'bil/search/SET_TOTAL_PAGES'
const SET_SEARCH_TEXT = 'bil/search/SET_SEARCH_TEXT'
const SET_ARTIGOS = 'bil/search/SET_ARTIGOS'
const SET_ARTIGOS_FROM_SEARCH = 'bil/search/SET_ARTIGOS_FROM_SEARCH'
const SET_RESULT_SIZE = 'bil/search/SET_RESULT_SIZE'
const SET_LANG = 'bil/search/SET_LANG'
const SET_FAMILIA_ID = 'bil/search/SET_FAMILIA_ID'
const RESET_ARTIGOS = 'bil/search/RESET_ARTIGOS'
const SEARCH_ARTIGOS_LOADING = 'bil/search/SEARCH_ARTIGOS_LOADING'
const SEARCH_ARTIGOS_ERROR = 'bil/search/SEARCH_ARTIGOS_ERROR'

const initialState = {
  searchText: '',
  searchResult: null,
  currentPage: 1,
  pageSize: 20,
  totalPages: 0,
  artigos: [],
  artigosFromSearch: false,
  resultSize: 0,
  lang: 'pt',
  familiaId: null,
  searchArtigosIsLoading: false,
  searchArtigosErrored: false,
  errorMessage: null
}

export const setCurrentPage = (page) => ({ type: SET_CURRENT_PAGE, page })
export const setSearchText = (text) => ({ type: SET_SEARCH_TEXT, text })
export const searchArtigosLoading = (loading) => ({ type: SEARCH_ARTIGOS_LOADING, loading })
export const searchArtigosError = (errored, message) => ({ type: SEARCH_ARTIGOS_ERROR, errored, message })

export function resetArtigos () {
  return (dispatch) => {
    dispatch({ type: RESET_ARTIGOS })
  }
}

export function artigosDaFamilia (familiaId, lang, pageSize) {
  return (dispatch) => {
    dispatch({ type: SET_ARTIGOS_FROM_SEARCH, fromSearch: false })
    dispatch({ type: SET_CURRENT_PAGE, page: 1 })
    const pageSizeParam = '&pageSize=' + pageSize
    const pageParam = '&page=1'
    axios.get(`${host}/api/public/produtos/familia/${familiaId}?lang=${lang}${pageSizeParam}${pageParam}`)
      .then(res => res.data)
      .then(post => {
        let pages = 0
        console.log(`post.resultSize`, post.resultSize)
        if (post.resultSize > 0) {
          pages = computeTotalPages(post.resultSize, pageSize)
        }
        console.log(`pages`, pages)
        dispatch({ type: SET_LANG, lang })
        dispatch({ type: SET_FAMILIA_ID, familiaId })
        dispatch({ type: SET_RESULT_SIZE, resultSize: post.resultSize })
        dispatch({ type: SET_TOTAL_PAGES, pages })
        dispatch({ type: SET_ARTIGOS, payload: post.list })
      })
      .catch(error => {
        console.log(`error`, error)
      })
  }
}

function artigosDaFamiliaPage (dispatch, state, page) {
  dispatch({ type: SET_CURRENT_PAGE, page: page })
  const pageSizeParam = '&pageSize=' + state.pageSize
  const pageParam = '&page=' + page
  axios.get(`${host}/api/public/produtos/familia/${state.familiaId}?lang=${state.lang}${pageSizeParam}${pageParam}`)
    .then(res => res.data)
    .then(post => {
      let pages = 0
      console.log(`post.resultSize`, post.resultSize)
      if (post.resultSize > 0) {
        pages = computeTotalPages(post.resultSize, state.pageSize)
      }
      console.log(`pages`, pages)
      dispatch({ type: SET_RESULT_SIZE, resultSize: post.resultSize })
      dispatch({ type: SET_TOTAL_PAGES, pages })
      dispatch({ type: SET_ARTIGOS_FROM_SEARCH, fromSearch: false })
      dispatch({ type: SET_ARTIGOS, payload: post.list })
    })
    .catch(error => {
      console.log(`error`, error)
    })
}

export function searchArtigos (text, lang, familiaId, pageSize) {
  return (dispatch) => {
    console.log('searchArtigos invocado')
    const familiaParam = !familiaId ? '' : '&familiaId=' + familiaId
    const pageSizeParam = '&pageSize=' + pageSize
    const pageParam = '&page=1'
    dispatch({ type: SET_ARTIGOS_FROM_SEARCH, fromSearch: true })
    dispatch({ type: SET_SEARCH_TEXT, text })
    dispatch({ type: SET_CURRENT_PAGE, page: 1 })
    dispatch(searchArtigosLoading(true))
    dispatch(searchArtigosError(false))
    axios.get(`${host}/api/public/search/produto?searchText=${text}&lang=
    ${lang}${familiaParam}${pageSizeParam}${pageParam}`)
      .then(res => res.data)
      .then(post => {
        let pages = 0
        if (post.resultSize > 0) {
          pages = computeTotalPages(post.resultSize, pageSize)
        }
        dispatch(searchArtigosLoading(false))
        dispatch({ type: SET_LANG, lang })
        dispatch({ type: SET_FAMILIA_ID, familiaId })
        dispatch({ type: SET_RESULT_SIZE, resultSize: post.resultSize })
        dispatch({ type: SEARCH_ARTIGOS, payload: post })
        dispatch({ type: SET_TOTAL_PAGES, pages })
        const artigos = post.list.map(obj => {
          return obj.obj
        })
        dispatch({ type: SET_ARTIGOS, payload: artigos })
      })
      .catch(error => {
        console.log('Erro obtido:', error.response)
        console.log('Erro obtido:', error.response.data.error_description)
        dispatch(searchArtigosLoading(false))
        dispatch(searchArtigosError(true, error.response.data.error_description))
        dispatch({ type: RESET_ARTIGOS })
      })
  }
}

export function goPage (page, state) {
  if (!canGoPage(page, state.currentPage, state.resultSize, state.totalPages)) {
    return (dispatch) => { }
  }
  if (typeof page === 'string') {
    page = parseInt(page)
  }
  return (dispatch) => {
    if (state.artigosFromSearch) {
      search(dispatch, state, page)
    } else {
      artigosDaFamiliaPage(dispatch, state, page)
    }
  }
}

function search (dispatch, state, page) {
  const familiaParam = !state.familiaId ? '' : '&familiaId=' + state.familiaId
  const pageSizeParam = '&pageSize=' + state.pageSize
  const pageParam = '&page=' + page
  dispatch({ type: SET_CURRENT_PAGE, page: page })
  axios.get(`${host}/api/public/search/produto?searchText=${state.searchText}&lang=
    ${state.lang}${familiaParam}${pageSizeParam}${pageParam}`)
    .then(res => res.data)
    .then(post => {
      let pages = 0
      if (post.resultSize > 0) {
        pages = computeTotalPages(post.resultSize, state.pageSize)
      }
      dispatch({ type: SET_RESULT_SIZE, resultSize: post.resultSize })
      dispatch({ type: SEARCH_ARTIGOS, payload: post })
      dispatch({ type: SET_TOTAL_PAGES, pages })
      const artigos = post.list.map(obj => {
        return obj.obj
      })
      dispatch({ type: SET_ARTIGOS_FROM_SEARCH, fromSearch: true })
      dispatch({ type: SET_ARTIGOS, payload: artigos })
    })
    .catch(error => {
      console.log(error)
    })
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_ARTIGOS:
      return { ...state, searchResult: action.payload }
    case SET_TOTAL_PAGES:
      return { ...state, totalPages: action.pages }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.text }
    case SET_ARTIGOS:
      return { ...state, artigos: action.payload }
    case SET_ARTIGOS_FROM_SEARCH:
      return { ...state, artigosFromSearch: action.fromSearch }
    case SET_LANG:
      return { ...state, lang: action.lang }
    case SET_FAMILIA_ID:
      return { ...state, familiaId: action.familiaId }
    case RESET_ARTIGOS:
      return {
        ...state,
        familiaId: null,
        currentPage: 1,
        totalPages: 0,
        artigos: [],
        artigosFromSearch: false,
        resultSize: 0
      }
    case SEARCH_ARTIGOS_LOADING:
      return { ...state, searchArtigosIsLoading: action.loading }
    case SEARCH_ARTIGOS_ERROR:
      return { ...state, searchArtigosErrored: action.errored, errorMessage: action.message }
    default:
      return state
  }
}

export function canGoPage (page, currentPage, resultSize, totalPages) {
  page = parseInt(page)
  if (isNaN(page)) {
    return false
  }
  if (resultSize === undefined ||
    currentPage === page ||
    page > totalPages ||
    page < 1) {
    return false
  }
  return true
}

function computeTotalPages (resultSize, pageSize) {
  if (!resultSize) {
    return 0
  }
  if (resultSize <= pageSize) {
    return 1
  }
  return 1 + Math.floor((resultSize - 1) / pageSize)
}