import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'

import styles from './AboutUs.module.css'
import GamaProdutos from '../GamaProdutos/GamaProdutos'
export default class AboutUsBodyPt extends Component {
  render () {
    return (
      <div className={styles.formContainer}>
        <h2><FM id='aboutUs.info.title' defaultMessage='Materializamos' /></h2>
        <p>A empresa Baptista &amp; Irmão, S.A., apesar dos seus 75 anos de história tem procurado ser jovial e empreendedora. Os principais ativos que a empresa detém são a sua extraordinária equipa de trabalho e a quantidade de parceiros que tem vindo a desenvolver ao longo destes anos.</p>
        <p>A empresa assenta o seu modelo de negócios em 3 prismas.</p>
        <ul>
          <li><p>Produção própria: Redes Eletrosoldadas; Redes Ovinas; Malhas Soltas; Painéis de Vedação; Prego; Arame Farpado.</p></li>
          <li><p>I+D – A empresa tem investido na procura de novos nichos de mercados e produtos que se diferenciem do tradicional.</p></li>
          <p>Para poder tornar realidade os dois primeiros pontos temos investido fortemente em tecnologia de ponta no sentido de melhorarmos a qualidade dos produtos por nós produzidos adequando-os às crescentes necessidades do mercado. Temos procurado encontrar nichos de mercado que se diferenciem pela qualidade.</p>
          <li><p>Comercialização de Produtos: Tubos Gerais; Tubo Calibrado; Tubos Sem Costura. Ferro; Chapa; Acessórios; Redes Comerciais; Inox; Arame.</p></li>
        </ul>
        <p>Estamos a aumentar as infraestruturas com o sentido de incrementar ainda mais a gama de produtos por nós comercializados. A melhoria das condições de trabalho e o aumento de satisfação dos nossos colaboradores serão uma realidade a curto médio prazo.</p>
        <h3>Qual a missão da empresa:</h3>
        <p>Pretendemos dizer a todos os nossos parceiros que o “milagre da multiplicação é fundamental”. Crescei e multiplicai-vos nós estaremos presentes para materializar o vosso sucesso e crescer convosco.</p>
        <div className={styles.servicosMainContainer}>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.valorizacao} />
            <div className={styles.servicosTitle}>Valorização</div>
            <p>Procuramos motivar e valorizar a equipa de trabalho no sentido de conseguir detetar as reais necessidades dos nossos parceiros.</p>
          </div>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.qualidade} />
            <div className={styles.servicosTitle}>Qualidade</div>
            <p>Procuramos a versatilidade de encontrar a qualidade necessária a cada cliente com a finalidade final que este fique satisfeito.</p>
          </div>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.satisfacao} />
            <div className={styles.servicosTitle}>Satisfação</div>
            <p>A satisfação de cada parceiro pauta-se pela diferença de abordagem, por isso, é fundamental incutir o espirito de honestidade e transparência nas relações.</p>
          </div>
        </div>
        <GamaProdutos />
      </div>
    )
  }
}
