import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'

import styles from './TopFooter.module.css'
import logoimg from '../../images/Bil-Logo-Verde.svg'

export default class TopFooter extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.gridContainer}>
          <div className={styles.contactosGrid}>
            <div><FM id='topFooter.email1.label' defaultMessage='email1' /></div>
            <a className={styles.href} href='mailto:liliana@bil.pt'>liliana@bil.pt</a>
          </div>
          <div className={styles.contactosGrid}>
            <div><FM id='topFooter.email2.label' defaultMessage='email2' /></div>
            <a className={styles.href} href='mailto:eliana@bil.pt'>eliana@bil.pt</a>
          </div>
          <div className={styles.contactosGrid}>
            <div><FM id='topFooter.email3.label' defaultMessage='email3' /></div>
            <a className={styles.href} href='mailto:beta.duarte@bil.pt'>beta.duarte@bil.pt</a>
          </div>
          <div className={styles.contactosGrid}>
            <div><FM id='topFooter.email4.label' defaultMessage='email4' /></div>
            <a className={styles.href} href='mailto:bil.sa@bil.pt'>bil.sa@bil.pt</a>
          </div>
        </div>
        <Link to='/' className={styles.homeContainer}>
          <div to='/' className={styles.logo}>
            <img className={styles.img} src={logoimg} alt='Logo' />
          </div>
          <div to='/' className={styles.homeLink}>Home</div>
        </Link>
      </div>
    )
  }
}
