import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'

import styles from './Artigos.module.css'
// import ArtigosFilter from './ArtigosFilter'
import FamiliaFilter from './FamiliaFilter'
// import ArtigosLista from './ArtigosLista'
// import ItemsConsulta from '../CarroConsulta/ItemsConsulta'
import ArtigoCustom from './ArtigoCustom'
// import SearchArtigos from './SearchArtigos'
import ArtigosListaSearch from './ArtigosListaSearch'
import GamaProdutos from '../GamaProdutos/GamaProdutos'
import PageHeader from '../PageHeader/PageHeader'
import { searchArtigos, setSearchText } from '../../modules/search'
import { setStaticMenu } from '../../modules/geral'

class Artigos extends Component {
  constructor(props) {
    super(props)
    const { match: { params, path }, pageSize, lang, searchArtigosIsLoading } = props
    this.props.setStaticMenu(path)
    console.log(`path`, path)
    console.log(`searchArtigosIsLoading`, searchArtigosIsLoading)
    if (!searchArtigosIsLoading && params.stext) {
      console.log('search from link directo')
      this.props.searchArtigos(params.stext, lang, null, pageSize)
      this.props.setSearchText(params.stext)
    }
  }

  render () {
    return (
      <div>
        <PageHeader
          title={'artigos.title'}
          subtitle={null}
          shadowTitle
          formname={'artigos'} />
        <div className={styles.body}>
          {/* <h1><FM id='artigos.title' defaultMessage='Artigos' /></h1> */}
          {/* <SearchArtigos /> */}
          {/* <ItemsConsulta /> */}
          <div className={styles.filterFamilia}><FM id='artigos.filterbyfammily' defaultMessage='Filtrar por família' /></div>
          <div className={styles.filterFamilia}>
            <FM id='artigos.addToOrcamento' defaultMessage='Clique em "Adicionar" para adicionar ao' />&nbsp;
            <Link to='/carro-consulta' className={styles.link}><strong><FM id='artigos.addToOrcamentoLink' defaultMessage='Pedido de Orçamento' /></strong></Link>
          </div>
          <FamiliaFilter />
          {/* <ArtigosFilter /> */}
          <ArtigosListaSearch />
          <ArtigoCustom />
          {/* <ArtigosLista /> */}
          <GamaProdutos />
        </div>
      </div>
    )
  }
}

Artigos.propTypes = {
  currentFamilia: PropTypes.object,
  lang: PropTypes.string.isRequired,
  searchArtigos: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchArtigosIsLoading: PropTypes.bool.isRequired,
  setStaticMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentFamilia: state.familias.currentFamilia,
  lang: state.language.lang,
  pageSize: state.search.pageSize,
  searchArtigosIsLoading: state.search.searchArtigosIsLoading
})

const mapDispatchToProps = { searchArtigos, setSearchText, setStaticMenu }

export default connect(mapStateToProps, mapDispatchToProps)(Artigos)
