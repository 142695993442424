import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ArtigosLista.module.css'
import { isOdd } from '../../utils'
import { appendItem, incCount } from '../../modules/carroConsulta'
import { filterArtigos } from '../../modules/artigos'
import { setDisplayNewArtigoAdded } from '../../modules/geral'

class ArtigosLista extends Component {
  render () {
    const { artigos, selectedFiltros } = this.props
    if (!artigos) {
      return null
    }
    return (
      <div className={styles.body}>
        <h2><FM id='artigos.artigosLista.title' defaultMessage='Lista' /></h2>
        <div className={styles.listaCont}>
          {this.buildTabela(artigos, selectedFiltros)}
        </div>
      </div>
    )
  }

  buildTabela (artigos, selectedFiltros) {
    const filteredArtigos = filterArtigos(artigos, selectedFiltros)
    return (
      <div className={styles.lista}>
        {
          filteredArtigos.map((artigo, index) => (
            <div key={artigo.codigo} className={isOdd(index) ? styles.rowOdd : styles.rowEven}>
              <div>{artigo.designacao}</div>
            </div>
          ))
        }
      </div>
    )
  }

  additemToCarroConsulta (artigo) {
    artigo.addedCarroConsulta = true
    this.props.appendItem(artigo)
    this.props.setDisplayNewArtigoAdded(true)
    // this.props.incCount()
  }
}

ArtigosLista.propTypes = {
  artigos: PropTypes.array,
  filtrosQualidade: PropTypes.array,
  appendItem: PropTypes.func.isRequired,
  incCount: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  selectedFiltros: PropTypes.array,
  setDisplayNewArtigoAdded: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  artigos: state.artigos.artigos,
  filtrosQualidade: state.artigos.filtrosQualidade,
  itemsCount: state.carroConsulta.itemsCount,
  selectedFiltros: state.artigos.selectedFiltros
})

const mapDispatchToProps = { appendItem, incCount, setDisplayNewArtigoAdded }

export default connect(mapStateToProps, mapDispatchToProps)(ArtigosLista)
