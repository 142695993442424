import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import styles from './Search.module.css'
import messages from '../../messages'
import { searchArtigos } from '../../modules/search'

class Search extends Component {
  constructor(props) {
    super(props)
    const { searchText } = props
    this.state = {
      searchText: !searchText ? '' : searchText,
      inline: false
    }
    this.textChanged = this.textChanged.bind(this)
    this.keyPressed = this.keyPressed.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onLostFocus = this.onLostFocus.bind(this)
    this.textRef = React.createRef()
  }

  componentDidUpdate () {
    const { searchText } = this.props
    if (!this.state.inline && searchText !== this.state.searchText) {
      this.setState({ searchText: searchText, inline: true })
    }
  }

  render () {
    const { lang, currentFamilia } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.searchCont}>
          <input ref={this.textRef} className={styles.searchInput} type='text'
            placeholder={messages[lang]['artigos.search.placeholder']} maxLength='256'
            value={this.state.searchText}
            onKeyPress={this.keyPressed}
            onChange={this.textChanged}
            onFocus={this.onFocus}
            onBlur={this.onLostFocus}
          />
          <div className={styles.searchButton}
            onClick={() => this.searchButtonClicked(this.state.searchText, lang, currentFamilia)} />
        </div>
      </div>
    )
  }

  onLostFocus (e) {
    let text = e.target.value
    if (text.trim() === '') {
      e.target.placeholder = messages[this.props.lang]['artigos.search.placeholder']
    }
  }

  onFocus (e) {
    e.target.placeholder = ''
  }

  keyPressed (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const { lang, currentFamilia } = this.props
      this.searchButtonClicked(this.state.searchText, lang, currentFamilia)
    }
  }

  textChanged (e) {
    this.setState({ searchText: e.target.value, inline: true })
  }

  searchButtonClicked (text, lang, familia) {
    this.textRef.current.focus()
    // if (!text || text.trim() === '' || text.length < 2) {
    //   return
    // }
    const { pageSize, history: { push } } = this.props
    // const familiaId=!familia ? null : familia.id
    console.log('search from Search clicked')
    this.props.searchArtigos(text, lang, null, pageSize)
    push('/artigos/' + text)
  }
}

Search.propTypes = {
  currentFamilia: PropTypes.object,
  lang: PropTypes.string.isRequired,
  searchArtigos: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  currentFamilia: state.familias.currentFamilia,
  lang: state.language.lang,
  pageSize: state.search.pageSize,
  searchText: state.search.searchText
})

const mapDispatchToProps = { searchArtigos }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
