import React, { Component } from 'react'

import styles from './Project.module.css'

export default class ProjectEn extends Component {
  render () {
    return (
      <div className={styles.formContainer}>
        <p><span>Project name: </span>+QI | BIL Qualification and Internationalization</p>
        <p><span>Project code: </span><nobr>POCI-02-0752-FEDER-021535</nobr></p>
        <p><span>Main objective: </span>Qualification of medium-sized companies</p>
        <p>Intervention region: <span>North</span></p>
        <p>Beneficiary entity: <span>BAPTISTA &amp; IRMÃO S.A.</span></p>
        <p>Approval date | <nobr>16-09-2016</nobr></p>
        <p>Start date | <nobr>16-05-2016</nobr></p>
        <p>Completion date | <nobr>15-05-2018</nobr></p>
        <p>Total eligible cost| 146.650,23 EUR</p>
        <p>European Union financial support | ERDF – 66.477,78 EUR</p>
        <p>With this internationalization project, Baptista &amp; Irmão, S.A. intends to adopt a market extension strategy aimed at entering new foreign markets, namely Mozambique, Cape Verde, São Tomé and Príncipe and Guinea Bissau, and consolidating its presence in the Angolan market .</p>
        <p>Given its vision, mission and approaches aimed at the international level, the entity defined, among others, the following objectives:</p>
        <p><span>-</span><span>Increase in International and Total Turnover by investing in new foreign markets;</span></p>
        <p><span>-</span><span>Betting on the qualification of the company's human resources structure;</span></p>
        <p><span>-</span><span>Innovate in terms of marketing and reinforce the image of the brand and the company.</span></p>
        <p>In order to put into practice the approach aimed at internationalization and achieve the defined objectives, it aims to carry out the following actions:</p>
        <p><span>-</span><span>Knowledge of foreign markets: participation as an exhibitor in fairs in the company's sector of activity;</span></p>
        <p><span>-</span><span>Prospection and presence in international markets: Conducting prospecting trips to target markets (Mozambique, Cape Verde, Angola and Guinea), enhancing the attraction of new/potential international customers;</span></p>
        <p><span>-</span><span>International Marketing: Development of catalogs and promotional video, as well as carrying out an economic feasibility study for the dissemination of its services in foreign markets and verification of the lines of action;</span></p>
        <p><span>-</span><span>Knowledge of foreign markets (Fairs/Exhibitions): Participation in international fairs to promote brand awareness and establish international contacts;</span></p>
        <p><span>-</span><span>Introduction of a new method of organization in commercial practices or in external relations: Hiring of two new qualified human resources, the first in International Relations and the second in Marketing.</span></p>
        <p>With this project, the entity aims to achieve an International Turnover in the year after the project of € 5,038,695.81 compared to a volume in the pre-project of € 1,478,870.7</p>
      </div>
    )
  }
}
