import React, { Component } from 'react'

import styles from './Home.module.css'
import MainDestaque from './MainDestaque'
import HomeNews from './HomeNews'
import SabiaQue from './SabiaQue'

export default class Home extends Component {
  render () {
    window.setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 200)
    return (
      <div className={styles.main}>
        <MainDestaque />
        <HomeNews />
        <SabiaQue />
      </div>
    )
  }
}
