import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ArtigosLista.module.css'
import { isOdd } from '../../utils'
import { appendItem } from '../../modules/carroConsulta'
import ListNav from './ListNav'
import { setDisplayNewArtigoAdded } from '../../modules/geral'

class ArtigosListaSearch extends Component {
  render () {
    const { artigos, itemsInProgress } = this.props
    if (!artigos || artigos.length === 0) {
      return null
    }
    return (
      <div className={styles.body}>
        <h2><FM id='artigos.artigosLista.title' defaultMessage='Lista' /></h2>
        <div className={styles.listaCont}>
          {this.buildTabela(artigos, itemsInProgress)}
          <ListNav />
        </div>
      </div>
    )
  }

  buildTabela (artigos, itemsCC) {
    const filteredArtigos = artigos
    return (
      <div className={styles.lista}>
        {
          filteredArtigos.map((artigo, index) => (
            <div key={artigo.id} className={isOdd(index) ? styles.rowOdd : styles.rowEven}>
              <div>{artigo.nome}</div>
              {
                this.artigoInCC(artigo, itemsCC)
                  ? <div className={styles.artigoAdded} />
                  : <div className={styles.actionItem} onClick={() => this.additemToCarroConsulta(artigo)}>
                    <FM id='artigo.addShort' defaultMessage='adicionar' /></div>
              }
            </div>
          ))
        }
      </div>
    )
  }

  artigoInCC (artigo, itemsCC) {
    for (let index = 0; index < itemsCC.length; index++) {
      const element = itemsCC[index];
      if (artigo.id === element.id) {
        return true
      }
    }
    return false
  }

  additemToCarroConsulta (artigo) {
    artigo.addedCarroConsulta = true
    this.props.appendItem(artigo)
    this.props.setDisplayNewArtigoAdded(true)
  }
}

ArtigosListaSearch.propTypes = {
  artigos: PropTypes.array,
  appendItem: PropTypes.func.isRequired,
  itemsInProgress: PropTypes.array,
  setDisplayNewArtigoAdded: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  artigos: state.search.artigos,
  itemsInProgress: state.carroConsulta.itemsInProgress
})

const mapDispatchToProps = { appendItem, setDisplayNewArtigoAdded }

export default connect(mapStateToProps, mapDispatchToProps)(ArtigosListaSearch)
