import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './MainMenu.module.css'
import MenuHeader from './MenuHeader'
import SubMenu from './SubMenu'

class MainMenu extends Component {
  render () {
    const { items, match: { params } } = this.props
    if (!params || !params.id) {
      return null
    }
    const selectedItem = this.getSelectedItem(items, params.id)
    if (!selectedItem) {
      return null
    }
    return (
      <div className={styles.main}>
        <MenuHeader
          title={selectedItem.nome}
          subtitle={selectedItem.descricao}
        />
        <div className={styles.body}>
          {this.drawFamilias(selectedItem)}
        </div>
      </div>
    )
  }

  getSelectedItem (items, id) {
    if (!items || !items.length) {
      return null
    }
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.id === id) {
        return element
      }
      let list = this.getSelectedItem(element.children, id)
      if (list) {
        return list
      }
    }
    return null
  }

  drawFamilias (item) {
    if (!item.children || !item.children.length) {
      return null
    }
    return (
      <>
        {
          item.children.map(item => {
            const familiaId = !item.familia ? null : item.familia.id
            const path = !item.children || !item.children.length ? '/familia/' + familiaId : '/menu-familia/' + item.id
            return (
              <>
                <div key={item.id} className={styles.row}>
                  <Link key={item.id + 'link'} to={path}
                    className={styles.menuItem}>
                    {item.nome}
                  </Link>
                  <div className={styles.descricao}>{item.descricao}</div>
                </div>
                <SubMenu key={item.id + 'subitems'} item={item} />
              </>
            )
          })
        }
      </>
    )
  }
}

MainMenu.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object
}

const mapStateToProps = state => ({
  items: state.menuFamilias.items
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
