import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

import styles from './HomeCarousel.module.css'

export default class HomeCarousel extends Component {
  render () {
    return (
      <Carousel>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img1} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img2} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img3} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img4} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img5} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img6} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img7} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img8} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img9} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img10} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img11} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img12} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img13} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img14} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.imgCont}>
            <div className={styles.img15} />
          </div>
        </Carousel.Item>
      </Carousel>
    )
  }
}
