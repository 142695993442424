/* eslint-env browser */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import 'bootstrap/dist/css/bootstrap.min.css'

import styles from './App.module.css'
import messages from './messages'
import LeftBar from './components/LeftBar/LeftBar'
import Search from './components/Search/Search'
import Home from './components/Home/Home'
import Footer from './components/Footer/Footer'
import ContactForm from './components/ContactForm/ContactForm'
import Project from './components/Project/Project'
import AboutUs from './components/AboutUs/AboutUs'
import Artigos from './components/Artigos/Artigos'
import CarroConsulta from './components/CarroConsulta/CarroConsulta'
import MainMenu from './components/MenuFamilias/MainMenu'
import Familia from './components/Familia/Familia'
import ServicoEntregas from './components/ServicoEntregas/ServicoEntregas'
import ScrollTop from './components/ScrollTop/ScrollTop'
import MessageManager from './components/MessageManager/MessageManager'
import SpinnerManager from './components/MessageManager/SpinnerManager'
import Qualidades from './components/Qualidades/Qualidades'

class App extends Component {
  render () {
    const { lang } = this.props
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router>
          <div className={styles.app}>
            <LeftBar />
            <div className={styles.rightBarCont}>
              <Search />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/artigos/:stext' render={(props) => <Artigos {...props} />} />
                <Route path='/carro-consulta' component={CarroConsulta} />
                <Route path='/artigos' component={Artigos} />
                <Route path='/about-us' component={AboutUs} />
                <Route path='/project' component={Project} />
                <Route path='/contact' component={ContactForm} />
                <Route path='/menu-familia/:id' render={(props) => <MainMenu {...props} />} />
                <Route path='/familia/:id' render={(props) => <Familia {...props} />} />
                <Route path='/entregas-fast' component={ServicoEntregas} />
                <Route path='/qualidades' component={Qualidades} />
              </Switch>
            </div>
            <Footer />
          </div>
          <ScrollTop />
          <MessageManager />
          <SpinnerManager />
        </Router>
      </IntlProvider>
    )
  }
}

App.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App)
