import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'

import styles from './PageHeader.module.css'

export default class PageHeader extends Component {
  render () {
    const { title, subtitle, formname, shadowTitle, shadowSubtitle } = this.props
    return (
      <div className={styles.mainCont}>
        <div className={styles.main + ' ' + styles[formname]}>
          <h1 className={styles.h1 + (shadowTitle ? ' ' + styles.shadowTitle : '')}>
            <FM id={title} defaultMessage='' />
          </h1>
          {
            subtitle
              ? <div className={styles.subtitle + (shadowSubtitle ? ' ' + styles.shadowSubtitle : '')}>
                <FM id={subtitle} defaultMessage='' />
              </div>
              : null
          }
        </div >
      </div>
    )
  }
}
