import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import Select from 'react-select'

import styles from './ArtigosFilter.module.css'
import { setFiltro } from '../../modules/artigos'
// import Filter from './Filter'

class ArtigosFilter extends Component {
  render () {
    const { filtrosQualidade } = this.props
    if (!filtrosQualidade) {
      return null
    }
    return (
      <div className={styles.body}>
        <h2><FM id='artigos.artigosFilter.title' defaultMessage='Filtro' /></h2>
        {this.displayFiltros(filtrosQualidade)}
      </div>
    )
  }

  displayFiltros (arrays) {
    return (
      <div className={styles.filtros}>
        {
          arrays.map((codigos, index) => {
            const options = codigos.arr.map((item, index) => {
              return { value: index, label: item }
            })
            return (
              // <Filter key={index} options={options} codigos={codigos} />
              <Select key={index} className={styles.select}
                placeholder={codigos.label} options={options}
                isClearable={true}
                onChange={(e) => this.selectFiltro(e, codigos)} />
            )
          })
        }
      </div>
    )
  }

  selectFiltro (e, codigos) {
    if (!e) {
      this.props.setFiltro({ start: codigos.start, size: codigos.size, value: null })
    } else {
      this.props.setFiltro({ start: codigos.start, size: codigos.size, value: e.label })
    }
    // console.log(e)
    // console.log(codigos)
  }

}

ArtigosFilter.propTypes = {
  artigos: PropTypes.array,
  filtrosQualidade: PropTypes.array,
  setFiltro: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  artigos: state.artigos.artigos,
  filtrosQualidade: state.artigos.filtrosQualidade
})

const mapDispatchToProps = { setFiltro }

export default connect(mapStateToProps, mapDispatchToProps)(ArtigosFilter)
