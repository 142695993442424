import React, { Component } from 'react'

import styles from './MainDestaque.module.css'
import HomeCarousel from './HomeCarousel'
import GoArtigos from './GoArtigos'
import GoOrcamento from './GoOrcamento'

export default class MainDestaque extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.topGrid}>
          <HomeCarousel />
          <GoArtigos />
        </div>
        <GoOrcamento />
      </div>
    )
  }
}
