import React, { Component } from 'react'
import Toast from 'react-bootstrap/Toast'

import styles from './MyToast.module.css'

export default class MyToast extends Component {
  render () {
    const { show, header, body, delay = 10000, onClose, success, error, help } = this.props
    const style = {
      flexBasis: 'unset'
    }
    const classCss = (success ? styles.success : (error ? styles.error : (help ? styles.help : styles.info)))
    return (
      <Toast show={show} onClose={onClose}
        animation={true}
        style={style}
        delay={delay} autohide={!delay ? false : true}>
        <Toast.Header className={classCss}>
          <img src='../../images/Bil-Logo-Verde.svg' width={'40px'} height={'40px'} className='rounded mr-2' alt='' />
          <div className={styles.headerText}>{header}</div>
        </Toast.Header>
        <Toast.Body><div className={styles.bodyText}>{body}</div></Toast.Body>
      </Toast>
    )
  }
}
