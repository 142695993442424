import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ContactForm.module.css'
import PageHeader from '../PageHeader/PageHeader'
import messages from '../../messages'
import GoogleMap from '../GoogleMap'
import { mockSubmitContacto, updateContacto, resetContacto, submitContacto } from '../../modules/contactos'
import { setContactosVisited, setContactosHelpDisplayed } from '../../modules/geral'
import { setStaticMenu } from '../../modules/geral'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.nomeRef = React.createRef()
    this.telefRef = React.createRef()
    this.emailRef = React.createRef()
    this.empresaRef = React.createRef()
    this.mensagemRef = React.createRef()
    const { contactosVisited, match: { path } } = props
    this.props.setStaticMenu(path)

    if (!contactosVisited) {
      props.setContactosVisited(true)
    }
    const { contacto } = props
    this.state = {
      nome: contacto.nome,
      telefone: contacto.telefone,
      email: contacto.email,
      empresa: contacto.empresa,
      mensagem: contacto.mensagem
    }
  }

  componentWillUnmount () {
    this.props.setContactosHelpDisplayed(true)
  }

  render () {
    const { lang } = this.props
    return (
      <div className={styles.main}>
        <PageHeader
          title={'contacto.header.title'}
          subtitle={'contacto.header.subtitle'}
          shadowTitle
          shadowSubtitle
          formname={'contacto'} />
        <div className={styles.formContainer}>
          <form onSubmit={(e) => this.submitForm(e)}>
            <h2>
              <FM id='contacto.title' defaultMessage='Formulário' />
            </h2>
            <div className={styles.formGrid}>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='contacto.form.nome' defaultMessage='Nome' />&nbsp;*</label>
                <input ref={this.nomeRef} className={styles.input} id='nome' name='nome' type='text'
                  required maxLength='256'
                  onInvalid={() => this.nomeRef.current.setCustomValidity(messages[lang]['contacto.form.nomeErr'])}
                  onInput={() => this.nomeRef.current.setCustomValidity('')}
                  value={this.state.nome}
                  onChange={this.nomeUpdate} />
              </div>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='contacto.form.telef' defaultMessage='Telefone' />&nbsp;*</label>
                <input ref={this.telefRef} className={styles.input} id='telef' name='telef' type='text' required maxLength='15'
                  onInvalid={() => this.telefRef.current.setCustomValidity(messages[lang]['contacto.form.telefErr'])}
                  onInput={() => this.telefRef.current.setCustomValidity('')}
                  value={this.state.telefone}
                  onChange={this.telefoneUpdate} />
              </div>
            </div>
            <div className={styles.formGrid}>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='contacto.form.email' defaultMessage='email' />&nbsp;*</label>
                <input ref={this.emailRef} className={styles.input} id='email' name='email' type='text' required maxLength='256'
                  onInvalid={() => this.emailRef.current.setCustomValidity(messages[lang]['contacto.form.emailErr'])}
                  onInput={() => this.emailRef.current.setCustomValidity('')}
                  value={this.state.email}
                  onChange={this.emailUpdate} />
              </div>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}><FM id='contacto.form.empresa' defaultMessage='empresa' />&nbsp;*</label>
                <input ref={this.empresaRef} className={styles.input} id='empresa' name='empresa' type='text' required maxLength='256'
                  onInvalid={() => this.empresaRef.current.setCustomValidity(messages[lang]['contacto.form.empresaErr'])}
                  onInput={() => this.empresaRef.current.setCustomValidity('')}
                  value={this.state.empresa}
                  onChange={this.empresaUpdate} />
              </div>
            </div>
            <div>
              <div className={styles.textareaWrapper}>
                <label className={styles.label}><FM id='contacto.form.mensagem' defaultMessage='mensagem' />&nbsp;*</label>
                <textarea ref={this.mensagemRef} className={styles.textarea} id='mensagem' name='mensagem' required maxLength='5000'
                  onInvalid={() => this.mensagemRef.current.setCustomValidity(messages[lang]['contacto.form.mensagemErr'])}
                  onInput={() => this.mensagemRef.current.setCustomValidity('')}
                  value={this.state.mensagem}
                  onChange={this.mensagemUpdate} />
              </div>
            </div>
            <input type='submit' className={styles.button} value={messages[lang]['action.send']} />
          </form>
          <div className={styles.moradaContainer}>
            <div className={styles.moradaLeft}>
              <div className={styles.moradaTitulo}><FM id='contacto.info.morada' defaultMessage='morada' /></div>
              <div className={styles.moradaTitulo}><FM id='contacto.info.codPostal' defaultMessage='código' /></div>
              <div className={styles.moradaTitulo}><FM id='contacto.info.pais' defaultMessage='pais' /></div>
              <div className={styles.moradaTitulo}><FM id='contacto.info.telef' defaultMessage='telefone' /></div>
              <div className={styles.moradaTitulo}><FM id='contacto.info.email' defaultMessage='email' /></div>
            </div>
            <div className={styles.moradaRight}>
              <div className={styles.moradaTexto}>Rua Monsenhor Miguel de Oliveira 1488 – S. João de Ovar</div>
              <div className={styles.moradaTexto}>3880-796</div>
              <div className={styles.moradaTexto}>PORTUGAL</div>
              <div className={styles.moradaTexto}><a href='tel:+351256579310'>+351 256 579 310</a>&nbsp;<FM id='contacto.info.telefType' defaultMessage='(rede fixa)' /></div>
              <div className={styles.moradaTexto}><a href='mailto:bil.sa@bil.pt'>bil.sa@bil.pt</a></div>
            </div>
          </div>
        </div>
        <GoogleMap title={'aboutUs'} />
      </div>
    )
  }

  nomeUpdate = (e) => {
    this.setState({ nome: e.target.value })
    this.props.contacto.nome = e.target.value
    this.props.updateContacto(this.props.contacto)
  }

  telefoneUpdate = (e) => {
    this.setState({ telefone: e.target.value })
    this.props.contacto.telefone = e.target.value
    this.props.updateContacto(this.props.contacto)
  }

  emailUpdate = (e) => {
    this.setState({ email: e.target.value })
    this.props.contacto.email = e.target.value
    this.props.updateContacto(this.props.contacto)
  }

  empresaUpdate = (e) => {
    this.setState({ empresa: e.target.value })
    this.props.contacto.empresa = e.target.value
    this.props.updateContacto(this.props.contacto)
  }

  mensagemUpdate = (e) => {
    this.setState({ mensagem: e.target.value })
    this.props.contacto.mensagem = e.target.value
    this.props.updateContacto(this.props.contacto)
  }

  submitForm (e) {
    e.preventDefault()
    this.props.setContactosHelpDisplayed(true)
    // this.props.mockSubmitContacto(this.props.contacto)
    this.props.submitContacto(this.props.contacto, this.props.lang)
    this.setState({
      nome: '',
      telefone: '',
      email: '',
      empresa: '',
      mensagem: ''
    })
  }

}

ContactForm.propTypes = {
  lang: PropTypes.string.isRequired,
  contactosVisited: PropTypes.bool.isRequired,
  setContactosVisited: PropTypes.func.isRequired,
  setContactosHelpDisplayed: PropTypes.func.isRequired,
  mockSubmitContacto: PropTypes.func.isRequired,
  updateContacto: PropTypes.func.isRequired,
  resetContacto: PropTypes.func.isRequired,
  submitContacto: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang,
  contactosVisited: state.geral.contactosVisited,
  contacto: state.contactos.contacto,
  setStaticMenu: PropTypes.func.isRequired
})

const mapDispatchToProps = {
  setContactosVisited, setContactosHelpDisplayed, mockSubmitContacto,
  updateContacto, resetContacto, submitContacto, setStaticMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
