/* eslint-env browser */
import Keycloak from 'keycloak-js'

export const host = window.location.protocol === 'http:' ? 'https://bil-development.duckdns.org' : ''
export const hostUrl = 'https://' + (window.location.protocol === 'http:' ? 'bil-development.duckdns.org' : window.location.host)

export const isMobile = (window.innerWidth < 768)

export const kc = Keycloak({
  url: hostUrl + '/auth',
  realm: 'main',
  clientId: 'fe'
})

export const profileUrl = hostUrl + '/auth/realms/main/account?referrer=fo&redirect_uri=' + window.location.origin

export function generateUUID () {
  var d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += window.performance.now() // use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16)
  })
  return uuid
}