import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './LeftBar.module.css'
import logoimg from '../../images/Bil-Logo-Verde.svg'
import Login from './Login'
// import MenuFamilias from './MenuFamilias'
import MenuInst from './MenuInst'
import LangMenu from './LangMenu'
import { fetchMenuItems } from '../../modules/menuFamilias'
import { fetchFamilias } from '../../modules/familias'

class LeftBar extends Component {
  componentDidMount () {
    const { lang } = this.props
    this.props.fetchMenuItems(lang)
    this.props.fetchFamilias(lang)
  }

  componentDidUpdate () {
    const { lang } = this.props
    console.log('did update', lang)
    this.props.fetchMenuItems(lang)
  }
  render () {
    return (
      <div className={styles.main}>
        <Link to='/' className={styles.logo}>
          <img className={styles.img} src={logoimg} alt='Logo' />
        </Link>
        <Login />
        {/* <MenuFamilias /> */}
        <MenuInst />
        <LangMenu />
      </div>
    )
  }
}

LeftBar.propTypes = {
  lang: PropTypes.string.isRequired,
  fetchMenuItems: PropTypes.func.isRequired,
  fetchFamilias: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = { fetchMenuItems, fetchFamilias }

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar)
