import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl'

import styles from './AboutUs.module.css'
import GamaProdutos from '../GamaProdutos/GamaProdutos'

export default class AboutUsBodyEn extends Component {
  render () {
    return (
      <div className={styles.formContainer}>
        <h2><FM id='aboutUs.info.title' defaultMessage='Materializamos' /></h2>
        <p>Baptista &amp; Irmão S.A., despite its 75 Years of history, has sought to be youthful and entreprising. The companys main assets are its extraordinary work team and the number of partners iit has developing over these years</p>
        <p>The company bases its business modelo n 3 prisms:</p>
        <ul>
          <li><p>Own production: Electro-welded networks; sheep nets; fence panels; barbed wire.</p></li>
          <li><p>R &amp; D – The company has been investing in the search for new market niches and products that are diferente from the traditional ones.</p></li>
          <p>In order to make the first to points become reality, we´ve invested strongly in the newest and better technology in order to improve the quality of the products. The new products were adapted to the growing needs of the market. We´ve been trying to find market niches that stand out for their quality.</p>
          <li><p>Commercial Products: General Tubes; Calbrated Tube; Seamless tubes iron; Plates; Accessories; Commercial Nets; Stainless Steel; Wire</p></li>
        </ul>
        <p>We are increasing infrastructure in order to further increase the range of products we sell. Improving Working Conditions and increasing employee satisfaction will be a reality in the short to médium term.</p>
        <h3>What is the company’s mission:</h3>
        <p>We intend to tell our partners that the “miracle of multiplication is fundamental”. Grow and multiply, we will be present to materrialize your sucess and grow with you.</p>
        <div className={styles.servicosMainContainer}>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.valorizacao} />
            <div className={styles.servicosTitle}>Appreciation</div>
            <p>We seek to motivate and value the work team in order to detect the real needs of our products.</p>
          </div>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.qualidade} />
            <div className={styles.servicosTitle}>Quality</div>
            <p>We look for the versatility to find the quality needed by each customer with the ultimate goal of they satisfaction.</p>
          </div>
          <div className={styles.servicosContainer}>
            <div className={styles.servicosBall + ' ' + styles.satisfacao} />
            <div className={styles.servicosTitle}>Satisfaction</div>
            <p>The satisfaction of each partner is based on the difference in approach, which is why it’s essencial to instill the spirit of honesty and transparency in relationships.</p>
          </div>
        </div>
        <GamaProdutos />
      </div>
    )
  }
}
