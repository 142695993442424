import React, { Component } from 'react'

import styles from './HomeNews.module.css'
import Slide from '../Slide/Slide'

export default class HomeNews extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.title}>{'Em destaque'}</div>
        <div className={styles.parent}>
          <div>
            <Slide src='images/eletrosoldadas.jpg' type={'img1'} text='Eletrosoldadas' />
          </div>
          <div>
            <Slide src='images/fio.jpg' type={'img2'} text='Chapa industrial' />
          </div>
          <div>
            <Slide src='images/fio.jpg' type={'img3'} text='Tubos' />
          </div>
          <div>
            <Slide src='images/fio.jpg' type={'img4'} text='Serviços de entrega' to='/entregas-fast' />
          </div>
        </div>
      </div>
    )
  }
}
