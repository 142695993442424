import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import Select from 'react-select'

import styles from './FamiliaFilter.module.css'
import { fetchFamilia, setFamilia } from '../../modules/familias'
import { artigosDaFamilia, resetArtigos, searchArtigos } from '../../modules/search'

class FamiliaFilter extends Component {
  render () {
    const { familias, currentFamilia, pageSize } = this.props
    return (
      <div className={styles.body}>
        <h2><FM id='artigos.familiaFilter.title' defaultMessage='Família' /></h2>
        {this.buildFamiliasSelect(familias, currentFamilia, pageSize)}
      </div>
    )
  }

  buildFamiliasSelect (familias, currentFamilia, pageSize) {
    if (!familias || !familias.length) {
      return null
    }
    let currentIndex = null
    const options = familias.map((item, index) => {
      if (currentFamilia && item.id === currentFamilia.id) {
        currentIndex = index
      }
      return { value: item.id, label: item.nome }
    })
    return (
      <Select options={options} placeholder={'Seleccione a família'}
        defaultValue={!currentIndex ? null : options[currentIndex]}
        onChange={(value) => this.selectItem(value, pageSize)} isClearable={true} />
    )
  }

  selectItem (item, pageSize) {
    const { lang } = this.props
    if (!item) {
      this.props.setFamilia(null)
      this.props.resetArtigos()
      // this.props.searchArtigos(searchText, lang, null, pageSize)
    } else {
      this.props.fetchFamilia(lang, item.value)
      this.props.artigosDaFamilia(item.value, this.props.lang, pageSize)
      // this.props.searchArtigos(searchText, lang, item.value, pageSize)
    }
  }
}

FamiliaFilter.propTypes = {
  currentFamilia: PropTypes.object,
  familias: PropTypes.array.isRequired,
  fetchFamilia: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  setFamilia: PropTypes.func.isRequired,
  artigosDaFamilia: PropTypes.func.isRequired,
  artigosFromSearch: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  resetArtigos: PropTypes.func.isRequired,
  searchArtigos: PropTypes.func.isRequired,
  searchText: PropTypes.string
}

const mapStateToProps = state => ({
  currentFamilia: state.familias.currentFamilia,
  familias: state.familias.familias,
  lang: state.language.lang,
  artigosFromSearch: state.search.artigosFromSearch,
  pageSize: state.search.pageSize,
  searchText: state.search.searchText
})

const mapDispatchToProps = { fetchFamilia, setFamilia, artigosDaFamilia, resetArtigos, searchArtigos }

export default connect(mapStateToProps, mapDispatchToProps)(FamiliaFilter)
