import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import spinner from '../../css/Spinner.module.css'

class SpinnerManager extends Component {
  render () {
    const { contactoIsSubmitting, consultaIsSaving } = this.props
    const show = contactoIsSubmitting || consultaIsSaving
    if (!show) {
      return null
    }
    return (
      <div className={spinner.shadow}>
        <div className={spinner.loaderAbsolute} />
      </div>
    )
  }
}

SpinnerManager.propTypes = {
  contactoIsSubmitting: PropTypes.bool.isRequired,
  consultaIsSaving: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  contactoIsSubmitting: state.contactos.contactoIsSubmitting,
  consultaIsSaving: state.carroConsulta.consultaIsSaving
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerManager)
