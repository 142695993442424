import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './GoArtigos.module.css'

class GoArtigos extends Component {
  render () {
    const {lang}=this.props
    switch (lang) {
      case 'en':
        return this.en()
      default:
        return this.pt()
    }
  }

  pt(){
    return (
      <div className={styles.main}>
        <div>um pequeno passo da BIL...</div>
        <div>um grande passo rumo aos clientes</div>
        <div>sonhamos o futuro só para si.</div>
        <Link className={styles.button} to='/artigos'>clique no seu futuro</Link>
      </div>
    )
  }

  en(){
    return (
      <div className={styles.main}>
        <div>a small step from BIL...</div>
        <div>a big step toward clients</div>
        <div>We dream a future for you.</div>
        <Link className={styles.button} to='/artigos'>Click on your future</Link>
      </div>
    )
  }
}

GoArtigos.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GoArtigos)
