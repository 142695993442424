import axios from 'axios'
import { host } from '../request'
const SET_FAMILIAS = 'bil/artigos/SET_FAMILIAS'
const SET_CURRENT_FAMILIA_ID = 'bil/artigos/SET_CURRENT_FAMILIA_ID'
const ENABLE_ARTIGO = 'bil/artigos/ENABLE_ARTIGO'
const RESET_SELECTED_FILTROS = '/bil/artigos/RESET_SELECTED_FILTROS'
const SET_FILTRO = '/bil/artigos/SET_FILTRO'
const FAMILIAS_LOADING = 'bil/artigos/FAMILIAS_LOADING'
const FAMILIAS_LOADING_ERROR = 'bil/artigos/FAMILIAS_LOADING_ERROR'


const initialState = {
  familias: [{
    id: '01',
    designacao: 'Tubos de Aço redondo',
    codificacao: [
      {
        pos: 1,
        start: 0,
        size: 2,
        label: 'Prefixo',
        unidade: null,
        format: null,
        ignore: false
      }, {
        pos: 2,
        start: 2,
        size: 4,
        label: 'Espessura',
        unidade: 'mm',
        format: '000'
      }, {
        pos: 3,
        start: 6,
        size: 4,
        label: 'Diâmetro',
        unidade: 'mm',
        format: '000'
      }, {
        pos: 5,
        start: 10,
        size: 5,
        label: 'Comprimento',
        unidade: 'mm',
        format: '000'
      },
      {
        pos: 5,
        start: 15,
        size: 4,
        label: 'Qualidade',
        unidade: null,
        format: null
      }
    ],
    artigos: [{
      id: '1',
      codigoAs400: 'xyz',
      codigo: 'TC0100008005000DC01',
      designacao: 'TUBO RED. 8X1,00mm DC01 C/5000mm '
    }, {
      id: '2',
      codigo: 'TC0100008006000DC01',
      designacao: 'TUBO RED. 8X1,00mm DC01 C/6000mm '
    }, {
      id: '3',
      codigo: 'TC0100010006000DC01',
      designacao: 'TUBO RED. 10X1,00mm DC01 C/6000mm '
    }, {
      id: '4',
      codigo: 'TC0100010006000E155',
      designacao: 'TUBO RED. 10X1,00mm E155 C/6000mm'
    }, {
      id: '5',
      codigo: 'TC0100010006000E190',
      designacao: 'TUBO RED. 10X1,00mm E190 C/6000mm'
    }, {
      id: '6',
      codigo: 'TC0100010006000E220',
      designacao: 'TUBO RED. 10X1,00mm E220 C/6000mm'
    }, {
      id: '7',
      codigo: 'TC0100010006000E235',
      designacao: 'TUBO RED. 10X1,00mm E235 C/6000mm'
    }, {
      id: '8',
      codigo: 'TC0100010006000S235',
      designacao: 'TUBO RED. 10X1,00mm S235 C/6000mm'
    }]
  }, {
    id: '02',
    designacao: 'Tubos de Aço rectangulares',
    codificacao: [
      {
        pos: 5,
        start: 0,
        size: 2,
        label: 'Prefixo',
        unidade: null,
        format: null,
        ignore: true
      }, {
        pos: 4,
        start: 2,
        size: 4,
        label: 'Espessura',
        unidade: 'mm',
        format: '000'
      }, {
        pos: 2,
        start: 6,
        size: 4,
        label: 'Diâmetro',
        unidade: 'mm',
        format: '000'
      }, {
        pos: 3,
        start: 10,
        size: 5,
        label: 'Comprimento',
        unidade: 'mm',
        format: '000'
      },
      {
        pos: 1,
        start: 15,
        size: 4,
        label: 'Qualidade',
        unidade: null,
        format: null
      }
    ],
    artigos: [{
      id: '9',
      codigo: 'TC0100008005000DC01',
      designacao: 'TUBO RED. 8X1,00mm DC01 C/5000mm '
    }, {
      id: '10',
      codigo: 'TC0100008006000DC01',
      designacao: 'TUBO RED. 8X1,00mm DC01 C/6000mm '
    }, {
      id: '11',
      codigo: 'TC0100010006000DC01',
      designacao: 'TUBO RED. 10X1,00mm DC01 C/6000mm '
    }, {
      id: '12',
      codigo: 'TC0100010006000E155',
      designacao: 'TUBO RED. 10X1,00mm E155 C/6000mm'
    }, {
      id: '13',
      codigo: 'TC0100010006000E190',
      designacao: 'TUBO RED. 10X1,00mm E190 C/6000mm'
    }, {
      id: '14',
      codigo: 'TC0100010006000E220',
      designacao: 'TUBO RED. 10X1,00mm E220 C/6000mm'
    }, {
      id: '15',
      codigo: 'TC0100010006000E235',
      designacao: 'TUBO RED. 10X1,00mm E235 C/6000mm'
    }, {
      id: '16',
      codigo: 'TC0100010006000S235',
      designacao: 'TUBO RED. 10X1,00mm S235 C/6000mm'
    }]
  }],
  currentFamilia: null,
  filtrosQualidade: null,
  artigos: null,
  selectedFiltros: [],
  filteredArtigos: null,
  artigoCustom: null,
  familiasAreLoading: false,
  familiasLoadingErrored: false
}

export const setFamilias = (familias) => ({ type: SET_FAMILIAS, familias })
export const setCurrentFamiliaId = (familiaId) => ({ type: SET_CURRENT_FAMILIA_ID, familiaId })
export const enableArtigo = (id) => ({ type: ENABLE_ARTIGO, id })
export const resetSelectedFiltros = () => ({ type: RESET_SELECTED_FILTROS })
export const setFiltro = (filtro) => ({ type: SET_FILTRO, filtro })
export const familiasLoading = (loading) => ({ type: FAMILIAS_LOADING, loading })
export const familiasLoadingError = (errored) => ({ type: FAMILIAS_LOADING_ERROR, errored })

export function fetchFamilias (lang) {
  return (dispatch) => {
    dispatch(familiasLoading(true))
    dispatch(familiasLoadingError(false))
    axios.get(`${host}/api/public/familiasComArtigos?lang=${lang}`)
      .then(res => res.data)
      .then(posts => {
        console.log(`familias`, posts)
        // dispatch({
        //   type: SET_FAMILIAS,
        //   familias: posts
        // })
        dispatch(familiasLoading(false))
      })
      .catch(error => {
        console.log(`error`, error)
        dispatch(familiasLoading(false))
        dispatch(familiasLoadingError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_FAMILIAS:
      return { ...state, familias: action.familias }
    case SET_CURRENT_FAMILIA_ID:
      if (!action.familiaId) {
        return { ...state, currentFamilia: null, artigos: null, filtrosQualidade: null, selectedFiltros: [], filteredArtigos: null }
      }
      const familia = getCurrentFamilia(state.familias, action.familiaId)
      const resetedArtigos = getArtigos(familia)
      return { ...state, currentFamilia: familia, artigos: resetedArtigos, filtrosQualidade: buildArraysDeCodigos(familia), selectedFiltros: [], filteredArtigos: resetedArtigos }
    case ENABLE_ARTIGO:
      enableArtigoFamilias(state.familias, action.id)
      return { ...state }
    case RESET_SELECTED_FILTROS:
      return { ...state, selectedFiltros: [], filteredArtigos: state.artigos }
    case SET_FILTRO:
      let newFiltros = [...state.selectedFiltros]
      newFiltros = addFiltro(newFiltros, action.filtro)
      let newArtigos = [...state.artigos]
      newArtigos = filterArtigos(newArtigos, newFiltros)
      return { ...state, selectedFiltros: newFiltros, filteredArtigos: newArtigos }
    case FAMILIAS_LOADING:
      return { ...state, familiasAreLoading: action.loading }
    case FAMILIAS_LOADING_ERROR:
      return { ...state, familiasLoadingError: action.errored }
    default:
      return state
  }
}

export function filterArtigos (artigos, filtros) {
  if (!filtros || !filtros.length) {
    return artigos
  }
  let result = []
  for (let index = 0; index < artigos.length; index++) {
    const artigo = artigos[index];
    let adicionar = true
    for (let j = 0; j < filtros.length; j++) {
      const filtro = filtros[j];
      if (filtro.value !== null && artigo.codigo.substr(filtro.start, filtro.size) !== filtro.value) {
        adicionar = false
      }
    }
    if (adicionar) {
      result.push(artigo)
    }
  }
  return result
}

function addFiltro (filtros, filtro) {
  for (let index = 0; index < filtros.length; index++) {
    const element = filtros[index];
    if (element.start === filtro.start && element.size === filtro.size) {
      if (!filtro.value) {
        filtros.splice(index, 1)
        return filtros
      }
      element.value = filtro.value
      return filtros
    }
  }
  filtros.push(filtro)
  return filtros
}

function enableArtigoFamilias (familias, id) {
  for (let index = 0; index < familias.length; index++) {
    const element = familias[index];
    for (let j = 0; j < element.artigos.length; j++) {
      const artigo = element.artigos[j];
      if (artigo.id === id) {
        artigo.addedCarroConsulta = false
        return familias
      }
    }
  }
  return familias
}

function getCurrentFamilia (familias, id) {
  if (!id) {
    return null
  }
  for (let index = 0; index < familias.length; index++) {
    const element = familias[index];
    if (element.id === id) {
      return element
    }
  }
  return null
}

function getArtigos (familia) {
  return familia.artigos
}

function buildArraysDeCodigos (familia) {
  let arrays = []
  familia.codificacao.forEach(codigo => {
    if (!codigo.ignore) {
      arrays.push(getDistinctCodigos(codigo, familia.artigos))
    }
  });
  const sorted = arrays.sort((a, b) => {
    return a.pos - b.pos
  })
  return sorted
}

function getDistinctCodigos (codigo, artigos) {
  if (codigo.ignore) {
    return null
  }
  const start = codigo.start
  const len = codigo.size
  let arr = []
  artigos.forEach(art => {
    if (!arr.includes(art.codigo.substr(start, len))) {
      arr.push(art.codigo.substr(start, len))
    }
  })
  return { label: codigo.label + (!codigo.unidade ? '' : '/' + codigo.unidade), start: codigo.start, size: codigo.size, pos: codigo.pos, arr }
}