import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './MessageManager.module.css'
import { submitSuccess, consultaError } from '../../modules/carroConsulta'
import { submitContactoSuccess, submitContactoError } from '../../modules/contactos'
import {
  setContactosHelpDisplayed, setCarroConsultaHelpDisplayed, setDisplayNewArtigoCustomAdded,
  setDisplayNewArtigoAdded
} from '../../modules/geral'
import MyToast from '../MyToast/MyToast'
import messages from '../../messages'
import { searchArtigosError } from '../../modules/search'

class MessageManager extends Component {
  constructor(props) {
    super(props)
    this.closeSubmitCarroConsultaSuccessToast = this.closeSubmitCarroConsultaSuccessToast.bind(this)
    this.closeSubmitCarroConsultaErrorToast = this.closeSubmitCarroConsultaErrorToast.bind(this)
    this.dismissContactosHelp = this.dismissContactosHelp.bind(this)
    this.dismissContactoSuccess = this.dismissContactoSuccess.bind(this)
    this.dismissContactoError = this.dismissContactoError.bind(this)
    this.dismissCarroConsultaInfo = this.dismissCarroConsultaInfo.bind(this)
    this.dismissSearchError = this.dismissSearchError.bind(this)
    this.dismissNewArtigoCustomAdded = this.dismissNewArtigoCustomAdded.bind(this)
    this.dismissNewArtigoAdded = this.dismissNewArtigoAdded.bind(this)
  }

  render () {
    const { lang, consultaSubmittedSuccess, consultaErrored, contactosVisited, helpContactosDisplayed,
      contactoSubmissionSucceeded, contactoSubmissionErrored,
      carroConsultaVisited, helpCarroConsultaDisplayed, searchArtigosErrored, searchErrorMessage,
      displayNewArtigoCustomAdded, displayNewArtigoAdded } = this.props
    return (
      <div className={styles.main}>
        <MyToast show={consultaSubmittedSuccess}
          delay={5000}
          success
          header={messages[lang]['toast.success']}
          body={messages[lang]['toast.consultaSubmittedSuccess']}
          onClose={this.closeSubmitCarroConsultaSuccessToast}
        />
        <MyToast show={consultaErrored}
          delay={5000}
          error
          header={messages[lang]['toast.error']}
          body={messages[lang]['toast.consultaErrored']}
          onClose={this.closeSubmitCarroConsultaErrorToast}
        />
        <MyToast show={(contactosVisited || carroConsultaVisited) &&
          (!helpContactosDisplayed)}
          delay={5000}
          help
          header={messages[lang]['toast.help']}
          body={messages[lang]['toast.contactosVisited']}
          onClose={this.dismissContactosHelp}
        />
        <MyToast show={contactoSubmissionSucceeded}
          delay={5000}
          success
          header={messages[lang]['toast.success']}
          body={messages[lang]['toast.contactoSubmissionSucceeded']}
          onClose={this.dismissContactoSuccess}
        />
        <MyToast show={contactoSubmissionErrored}
          delay={5000}
          error
          header={messages[lang]['toast.error']}
          body={messages[lang]['toast.contactoSubmissionErrored']}
          onClose={this.dismissContactoError}
        />
        <MyToast show={carroConsultaVisited && !helpCarroConsultaDisplayed}
          delay={5000}
          header={messages[lang]['toast.info']}
          body={messages[lang]['toast.carroConsultaVisited']}
          onClose={this.dismissCarroConsultaInfo}
        />
        <MyToast show={searchArtigosErrored}
          delay={10000}
          error
          header={messages[lang]['toast.error']}
          body={searchErrorMessage}
          onClose={this.dismissSearchError}
        />
        <MyToast show={displayNewArtigoCustomAdded}
          delay={5000}
          success
          header={messages[lang]['toast.success']}
          body={messages[lang]['toast.customArtigoAdded']}
          onClose={this.dismissNewArtigoCustomAdded}
        />
        <MyToast show={displayNewArtigoAdded}
          delay={5000}
          success
          header={messages[lang]['toast.success']}
          body={messages[lang]['toast.artigoAdded']}
          onClose={this.dismissNewArtigoAdded}
        />
      </div>
    )
  }

  closeSubmitCarroConsultaSuccessToast () {
    this.props.submitSuccess(false)
  }

  closeSubmitCarroConsultaErrorToast () {
    this.props.consultaError(false)
  }

  dismissContactosHelp () {
    this.props.setContactosHelpDisplayed(true)
  }

  dismissContactoSuccess () {
    this.props.submitContactoSuccess(false)
  }

  dismissContactoError () {
    this.props.submitContactoError(false)
  }
  dismissCarroConsultaInfo () {
    this.props.setCarroConsultaHelpDisplayed(true)
  }

  dismissSearchError () {
    this.props.searchArtigosError(false)
  }

  dismissNewArtigoCustomAdded () {
    this.props.setDisplayNewArtigoCustomAdded(false)
  }

  dismissNewArtigoAdded () {
    this.props.setDisplayNewArtigoAdded(false)
  }

}

MessageManager.propTypes = {
  lang: PropTypes.string.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  consultaError: PropTypes.func.isRequired,
  contactosVisited: PropTypes.bool.isRequired,
  helpContactosDisplayed: PropTypes.bool.isRequired,
  setContactosHelpDisplayed: PropTypes.func.isRequired,
  contactoSubmissionSucceeded: PropTypes.bool.isRequired,
  submitContactoSuccess: PropTypes.func.isRequired,
  submitContactoError: PropTypes.func.isRequired,
  setCarroConsultaHelpDisplayed: PropTypes.func.isRequired,
  searchErrorMessage: PropTypes.string,
  searchArtigosErrored: PropTypes.bool.isRequired,
  searchArtigosError: PropTypes.func.isRequired,
  setDisplayNewArtigoCustomAdded: PropTypes.func.isRequired,
  displayNewArtigoCustomAdded: PropTypes.bool.isRequired,
  displayNewArtigoAdded: PropTypes.bool.isRequired,
  setDisplayNewArtigoAdded: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang,
  consultaErrored: state.carroConsulta.consultaErrored,
  consultaSubmittedSuccess: state.carroConsulta.consultaSubmittedSuccess,
  contactosVisited: state.geral.contactosVisited,
  helpContactosDisplayed: state.geral.helpContactosDisplayed,
  contactoSubmissionSucceeded: state.contactos.contactoSubmissionSucceeded,
  contactoSubmissionErrored: state.contactos.contactoSubmissionErrored,
  carroConsultaVisited: state.geral.carroConsultaVisited,
  helpCarroConsultaDisplayed: state.geral.helpCarroConsultaDisplayed,
  searchArtigosErrored: state.search.searchArtigosErrored,
  searchErrorMessage: state.search.errorMessage,
  displayNewArtigoCustomAdded: state.geral.displayNewArtigoCustomAdded,
  displayNewArtigoAdded: state.geral.displayNewArtigoAdded
})

const mapDispatchToProps = {
  submitSuccess, consultaError,
  setContactosHelpDisplayed, submitContactoSuccess,
  submitContactoError, setCarroConsultaHelpDisplayed, searchArtigosError,
  setDisplayNewArtigoCustomAdded, setDisplayNewArtigoAdded
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageManager)
