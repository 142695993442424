import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Qualidades.module.css'
import PageHeader from '../PageHeader/PageHeader'
import { setStaticMenu } from '../../modules/geral'

class Qualidades extends Component {
  constructor(props) {
    super(props)
    const { match: { path } } = props
    this.props.setStaticMenu(path)
  }
  
  render () {
    const { lang } = this.props
    return (
      <div>
        <PageHeader
          ageHeader
          title={'qualidades.title'}
          subtitle={null}
          shadowTitle
          formname={'qualidades'} />
        <div className={styles.body}>
          {this.drawParagrafo01(lang)}
          {this.drawTiposProdutos(lang)}
          {this.drawExemplos(lang)}
        </div>
      </div>
    )
  }

  drawParagrafo01 (lang) {
    switch (lang) {
      case 'en':
        return (
          <>
            <p>
              <strong>“Quality is suitability to use”</strong>. This is the technical definition established by ISO – INTERNATIONAL STANDARDIZATION ORGANIZATION, located in Switzerland and responsible for Quality standards in various sectors worldwide. However, when we talk about Quality, we must surrender to broader definitions. <strong>“Quality is primarly about the process by which products or services are materialized. If the process is carried out well, a good product will come naturally!”</strong>
            </p>
            <p>
              If we ask several lay people, “What is Quality?”, we will likely receive several diferente answers. <strong>Quality is linked to subjective feelings that reflect the internal needs of each one</strong>. Many people judge Quality by the appearance; others define the quality with the raw material that compose the final product; still others evaluate Quality by the price factor.
            </p>
            <p>
              At Bil, S.A. We adopt the most current quality measures in order to be able to comply with de highest level of demand from our customers. From reception to dispatch with processes for visualizing the surface status, Weighing, product measurements, traceability and analysis of the received certificates. However, we adapt the quality to the customer’s level of demand so as not to burden the final cost with processes that are nov valued by the customer.
            </p>
            <p>
              Bellow we present the <strong>technical sheets</strong> of the products sold by us.
            </p>
          </>
        )

      default:
        return (
          <>
            <p>
              <strong>“Qualidade é a adequação ao uso”</strong>. Esta é a definição técnica estabelecida pelo ISO – INTERNATIONAL STANDARDIZATION ORGANIZATION, situada na Suíça e responsável pelas normas de Qualidade, em diversos setores, no mundo inteiro. Contudo, quando falamos de Qualidade é forçoso rendermo-nos a definições mais abrangentes. <strong>“Qualidade tem a ver primordialmente, com o processo pelo qual os produtos ou serviços são materializados. Se o processo for bem realizado, um bom produto final advirá naturalmente”</strong>
            </p>
            <p>
              Se perguntarmos a várias pessoas leigas, “O que é Qualidade?”, provavelmente receberemos várias respostas diferentes. <strong>A Qualidade está ligada a sentimentos subjetivos que refletem as necessidades internas de cada um</strong>. Muitas pessoas avaliam a Qualidade pela aparência; outras definem a qualidade do material com a matéria prima que é elaborado o produto. Outras, ainda, avaliam a Qualidade pelo fator preço.
            </p>
            <p>
              Na BIL, S.A. adotamos as medidas de qualidade mais atuais de forma a podermos cumprir com o maior grau de exigência dos nossos clientes. Desde a receção à expedição com processos de visualização do estado de superfície, pesagem, medições dos produtos, rastreabilidade e análise dos certificados rececionados. Contudo, adequamos a qualidade ao grau de exigência do cliente de forma a não onerarmos o custo final com processos não valorizados pelo cliente.
            </p>
            <p>
              Abaixo apresentamos as <strong>fichas técnicas</strong> dos produtos comercializados por nós.
            </p>
          </>
        )
    }
  }

  drawTiposProdutos (lang) {
    switch (lang) {
      case 'en':
        return (
          <div className={styles.gridTipoProduto}>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.tubos}>
                <div className={styles.tipoProdutoTitle}>Tube</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10219-2.pdf' target='_blank'>Structural tube EN 10219</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10255_Canalizacao.pdf' target='_blank'>Water pipe tube EN 10255</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10305-3_e_10305-5.pdf' target='_blank'>Precision tube EN 10305</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/tubos_especiais.pdf' target='_blank'>Special Tubes (Handrail / Carpenter, etc…)</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.chapas}>
                <div className={styles.tipoProdutoTitle}>Plate</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Decapada_Negra.pdf' target='_blank'>Black steel plate EN 10051</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Galv_DX51_EN10143.pdf' target='_blank'>Galvanized steel plate EN 100143</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Industrial_EN10029.pdf' target='_blank'>Structural steel plate EN 10029</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Polida_Zincor_EN10131.pdf' target='_blank'>Polished and Zinc plate EN 10131</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Xadrez_Gotas_DIN59220.pdf' target='_blank'>Relief steel plate DIN 59220</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210702-Construcao_Qualidade_Chapa_Industrial.pdf' target='_blank'>Quality construction structural plate</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.ferro}>
                <div className={styles.tipoProdutoTitle}>Iron</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Barra_T.pdf' target='_blank'>T Bar</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Varao_Redondo_Vergalhao_Quadrado.pdf' target='_blank'>Square and round bar</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Cant_Abas_Desiguais.pdf' target='_blank'>Unequal angles</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Cant_Abas_Iguais.pdf' target='_blank'>Equal angles</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_HEAA-HEA-HEB-HEM-PAG1.pdf' target='_blank'>HEAA - HEA - HEB - HEM Profiles</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_IPE-IPEA-IPEAA.pdf' target='_blank'>IPE - IPEA - IPEAA Profiles</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_IPN.pdf' target='_blank'>IPN Profiles</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Fichas_Tecnicas_UPE.pdf' target='_blank'>UPE Profiles</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.producao}>
                <div className={styles.tipoProdutoTitle}>Production</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/Ficha_Tecnica_Paineis_Postes_Vedacao.pdf' target='_blank'>Fence Panels and Posts</a>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.gridTipoProduto}>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.tubos}>
                <div className={styles.tipoProdutoTitle}>Tubo</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10219-2.pdf' target='_blank'>Tubo estrutural EN 10219</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10255_Canalizacao.pdf' target='_blank'>Tubo canalização EN 10255</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Tubo_EN10305-3_e_10305-5.pdf' target='_blank'>Tubo precisão EN 10305</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/tubos_especiais.pdf' target='_blank'>Tubos Especiais (Corrimão / Carpinteiro, etc…)</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.chapas}>
                <div className={styles.tipoProdutoTitle}>Chapa</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Decapada_Negra.pdf' target='_blank'>Chapa negra EN 10051</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Galv_DX51_EN10143.pdf' target='_blank'>Chapa galvanizada EN 100143</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Industrial_EN10029.pdf' target='_blank'>Chapa industrial/Estrutural EN 10029</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Polida_Zincor_EN10131.pdf' target='_blank'>Chapa polida e Zincor EN 10131</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Chapa_Xadrez_Gotas_DIN59220.pdf' target='_blank'>Chapa com relevo DIN 59220</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210702-Construcao_Qualidade_Chapa_Industrial.pdf' target='_blank'>Construção Qualidade chapa industrial</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.ferro}>
                <div className={styles.tipoProdutoTitle}>Ferro</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Barra_T.pdf' target='_blank'>Barra T</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Varao_Redondo_Vergalhao_Quadrado.pdf' target='_blank'>Varão redondo e vergalhão quadrado</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Cant_Abas_Desiguais.pdf' target='_blank'>Cantoneiras abas desiguais</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_Cant_Abas_Iguais.pdf' target='_blank'>Cantoneiras abas iguais</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_HEAA-HEA-HEB-HEM-PAG1.pdf' target='_blank'>Perfis HEAA - HEA - HEB - HEM</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_IPE-IPEA-IPEAA.pdf' target='_blank'>Perfis IPE - IPEA - IPEAA</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Ficha_Tecnica_IPN.pdf' target='_blank'>Perfis IPN</a>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/20210625-Fichas_Tecnicas_UPE.pdf' target='_blank'>Perfis UPE</a>
              </div>
            </div>
            <div className={styles.tipoProduto}>
              <div className={styles.tipoProdutoBall + ' ' + styles.producao}>
                <div className={styles.tipoProdutoTitle}>Produção</div>
              </div>
              <div className={styles.linksCont}>
                <a className={styles.doc} href='docs/fichas_tecnicas_ftc/Ficha_Tecnica_Paineis_Postes_Vedacao.pdf' target='_blank'>Painéis e Postes de Vedação</a>
              </div>
            </div>
          </div>
        )
    }
  }

  drawExemplos (lang) {
    switch (lang) {
      case 'en':
        return (
          <>
            <div className={styles.exemplosTitle}>
              Let’s do manufacturing demonstrations bellow and the main differences between.
            </div>
            <p>
              Cold formed tube EN 10305 – EN 10219 – Factory Production
            </p>
            <img className={styles.imgExemplo1} src='images/exemplo1.jpg' alt='exemplo1' />
            <p /><p />
            <p style={{ 'marginTop': '1rem', 'marginBottom': '0' }}>
              Hot formed tube EN 10210 – Factory Production:
            </p>
            <div className={styles.grid}>
              <div className={styles.tipoProduto + ' ' + styles.title}> (Arrival Raw material)
                <img className={styles.imgProcesso} src='images/chegadaMatPrima.jpg' alt='processo 1' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Tube Conformation round)
                <img className={styles.imgProcesso} src='images/conformacaoRedondo.jpg' alt='processo 2' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Welding in round tube)
                <img className={styles.imgProcesso} src='images/soldaRedondo.jpg' alt='processo 3' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Tube conformation Square/Rect)
                <img className={styles.imgProcesso} src='images/conformQuadRet.jpg' alt='processo 4' />
              </div>
            </div>
            <p>
              Production flow of structural tube EN10210-1
            </p>
            <img className={styles.imgExemplo1} src='images/fluxoProdTuboEstrutural.png' alt='exemplo1' />
          </>
        )
      default:
        return (
          <>
            <div className={styles.exemplosTitle}>
              Vamos fazer abaixo demonstrações de fabrico e principais diferenças entre
            </div>
            <p>
              Tubo conformado a Frio EN 10305 – EN10219 - Produção em Fábrica:
            </p>
            <img className={styles.imgExemplo1} src='images/exemplo1.jpg' alt='exemplo1' />
            <p /><p />
            <p style={{ 'marginTop': '1rem', 'marginBottom': '0' }}>
              Tubo conformado a Quente EN 10210 - Produção em Fábrica:
            </p>
            <div className={styles.grid}>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Chegada mat. prima)
                <img className={styles.imgProcesso} src='images/chegadaMatPrima.jpg' alt='processo 1' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Conformação. Redondo)
                <img className={styles.imgProcesso} src='images/conformacaoRedondo.jpg' alt='processo 2' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Solda Redondo)
                <img className={styles.imgProcesso} src='images/soldaRedondo.jpg' alt='processo 3' />
              </div>
              <div className={styles.tipoProduto + ' ' + styles.title}>(Conform. Quad. e Ret.)
                <img className={styles.imgProcesso} src='images/conformQuadRet.jpg' alt='processo 4' />
              </div>
            </div>
            <p>
              Fluxo de produção de tubo estrutural EN10210-1
            </p>
            <img className={styles.imgExemplo1} src='images/fluxoProdTuboEstrutural.png' alt='exemplo1' />
          </>
        )
    }
  }
}

Qualidades.propTypes = {
  lang: PropTypes.string.isRequired,
  setStaticMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.language.lang
})

const mapDispatchToProps = { setStaticMenu }

export default connect(mapStateToProps, mapDispatchToProps)(Qualidades)
