const SET_CONTACTOS_VISITED = 'bil/geral/SET_CONTACTOS_VISITED'
const SET_CONTACTOS_HELP_DISPLAYED = 'bil/geral/SET_CONTACTOS_HELP_DISPLAYED'
const SET_CARRO_CONSULTA_VISITED = 'bil/geral/SET_CARRO_CONSULTA_VISITED'
const SET_CARRO_CONSULTA_HELP_DISPLAYED = 'bil/geral/SET_CARRO_CONSULTA_HELP_DISPLAYED'
const SET_DISPLAY_NEW_ARTIGO_CUSTOM_ADDED = 'bil/geral/SET_DISPLAY_NEW_ARTIGO_CUSTOM_ADDED'
const SET_DISPLAY_NEW_ARTIGO_ADDED = 'bil/geral/SET_DISPLAY_NEW_ARTIGO_ADDED'
const SET_STATIC_MENU = 'bil/geral/SET_STATIC_MENU'

const initialState = {
  contactosVisited: false,
  helpContactosDisplayed: false,
  carroConsultaVisited: false,
  helpCarroConsultaDisplayed: false,
  displayNewArtigoCustomAdded: false,
  displayNewArtigoAdded: false,
  staticMenu: null
}

export const setContactosVisited = (visited) => ({ type: SET_CONTACTOS_VISITED, visited })
export const setContactosHelpDisplayed = (displayed) => ({ type: SET_CONTACTOS_HELP_DISPLAYED, displayed })
export const setCarroConsultaVisited = (visited) => ({ type: SET_CARRO_CONSULTA_VISITED, visited })
export const setCarroConsultaHelpDisplayed = (displayed) => ({ type: SET_CARRO_CONSULTA_HELP_DISPLAYED, displayed })
export const setDisplayNewArtigoCustomAdded = (display) => ({ type: SET_DISPLAY_NEW_ARTIGO_CUSTOM_ADDED, display })
export const setDisplayNewArtigoAdded = (display) => ({ type: SET_DISPLAY_NEW_ARTIGO_ADDED, display })
export const setStaticMenu = (menu) => ({ type: SET_STATIC_MENU, menu })

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONTACTOS_VISITED:
      return { ...state, contactosVisited: action.visited }
    case SET_CONTACTOS_HELP_DISPLAYED:
      return { ...state, helpContactosDisplayed: action.displayed }
    case SET_CARRO_CONSULTA_VISITED:
      return { ...state, carroConsultaVisited: action.visited }
    case SET_CARRO_CONSULTA_HELP_DISPLAYED:
      return { ...state, helpCarroConsultaDisplayed: action.displayed }
    case SET_DISPLAY_NEW_ARTIGO_CUSTOM_ADDED:
      return { ...state, displayNewArtigoCustomAdded: action.display }
    case SET_DISPLAY_NEW_ARTIGO_ADDED:
      return { ...state, displayNewArtigoAdded: action.display }
    case SET_STATIC_MENU:
      return { ...state, staticMenu: action.menu }
    default:
      return state
  }
}